import { CommonResponse, get } from './common.ts'

// GET v1/booth/{idx}/electric-power

interface GetBoothElectricPowerResponse {
  usedStage: number
  amount: number
  isActive: boolean
}

export const GetBoothElectricPower = async (
  idx: number
): Promise<CommonResponse<GetBoothElectricPowerResponse>> => {
  return await get(`/booth/${idx}/electric-power`)
}
