import { CommonResponse, get } from './common.ts'

// GET v1/booth/{idx}/air-quality
interface GetBoothAirQualityResponse {
  co: number
  co2: number
  dust: number
  coStatus: string
  co2Status: string
  dustStaus: string
  coStatusColor: string
  co2StatusColor: string
  dustStatusColor: string
}

export const getBoothAirQuality = async (
  idx: number
): Promise<CommonResponse<GetBoothAirQualityResponse>> => {
  return await get(`/booth/${idx}/air-quality`)
}
