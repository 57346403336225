import { CommonResponse, get } from './common.ts'

// GET v1/booth/list

interface GetBoothListResponse {
  idx: number
  name: string
}

export const getBoothList = async (): Promise<CommonResponse<GetBoothListResponse>> => {
  return await get('/booth/list')
}
