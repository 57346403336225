import { CommonResponse, post } from './common.ts'

// POST v1/admin/update

interface PostAdminUpdateRequest {
  idx: number
  permission: JSON
}

export const postAdminUpdate = async (
  body: PostAdminUpdateRequest
): Promise<CommonResponse<null>> => {
  return await post('/admin/update', body)
}
