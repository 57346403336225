import { CommonResponse, post } from './common.ts'

// POST v1/booth/enroll

interface PostBoothEnrollRequest {
  company: string
  name: string
  address: string
  telNumber: string
  createdAt: string
}

export const postBoothEnroll = async (
  body: PostBoothEnrollRequest
): Promise<CommonResponse<null>> => {
  return await post('/booth/enroll', body)
}
