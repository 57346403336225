import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'

ChartJS.register(ArcElement, Tooltip, Legend)

const options = {
  maintainAspectRatio: false,
  responsive: true,
  layout: {
    padding: 0,
  },
  plugins: {
    legend: {
      display: false,
    },
  },
  cutout: '50%', // 도넛 차트의 가운데 비어있는 부분 크기 조정
}

export default function DoughnutChart({ graphData }) {
  return <Doughnut options={options} data={graphData} />
}
