import { CommonResponse, post } from './common.ts'

// POST v1/booth/{idx}/ventilator/used-stage

interface PostBoothVentilatorUsedStageRequest {
  stage: number
}

export const postBoothVentilatorUsedStage = async (
  idx: number,
  body: PostBoothVentilatorUsedStageRequest
): Promise<CommonResponse<null>> => {
  return await post(`/booth/${idx}/ventilator/used-stage`, body)
}
