import { CommonResponse, post } from './common.ts'

// POST v1/booth/{idx}/air-conditioner/temperature

interface PostBoothAirconditionerTemperatureRequest {
  temperature: number
}

export const postBoothAirconditionerTemperature = async (
  idx: number,
  body: PostBoothAirconditionerTemperatureRequest
): Promise<CommonResponse<null>> => {
  return await post(`/booth/${idx}/air-conditioner/temperature`, body)
}
