import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Header from 'components/Header'
import SideMenuBar from 'components/SideMenuBar'
import LineChart from 'components/LineChart'
import BarChartCo from 'components/BarChartCo'
import BarChartCo2 from 'components/BarChartCo2'

import DropDownSVG from 'assets/icons/chevron-down.svg'
import ClimateCelsiusSVG from 'assets/icons/climate-celsius.svg'
import UserMultipleSVG from 'assets/icons/user-multiple.svg'

import { getBoothList } from 'apis/boothlist.ts'
import { getBoothAirQuality } from 'apis/airquality.ts'
import { getBoothVisitor } from 'apis/visitor.ts'
import { getBoothTemperature } from 'apis/temperature.ts'

// const userData = [1, 1.5, 2.6, 3.7, 3, 1.2, 0, 0, 4.5, 3.5, 4.2]

// const labels = [
//   '09:30',
//   '09:35',
//   '09:40',
//   '09:45',
//   '09:50',
//   '09:55',
//   '10:00',
//   '10:05',
//   '10:10',
//   '10:15',
//   '10:20',
// ]

export default function BoothManagementOperationStatus() {
  const navigate = useNavigate()

  // selectedBooth: 현재 선택된 부스의 idx
  const [selectedBooth, setSelectedBooth] = useState(localStorage.getItem('selectedBoothIdx') || 0)
  // console.log(selectedBooth)

  // [BoothListApi]
  const [boothListResponse, setBoothListResponse] = useState([])
  const getBoothListApi = async () => {
    const getBoothListResponse = await getBoothList()

    if (getBoothListResponse.code !== '20') {
      alert(getBoothListResponse.message)
      navigate('/member/booth-registration')
      return
    }
    setBoothListResponse(getBoothListResponse.data)
    // console.log(getBoothListResponse.data)
    let firstBooth = getBoothListResponse.data[0].idx
    // console.log(firstBooth)

    setSelectedBooth(firstBooth)
    getBoothTemperatureApi(firstBooth)
    getBoothAirQualityApi(firstBooth)
    getBoothVisitorApi(firstBooth)
  }

  // [BoothTemperatureApi]
  const [boothTemperatureResponse, setBoothTemperatureResponse] = useState({})
  const getBoothTemperatureApi = async (boothIdx) => {
    const getBoothTemperatureResponse = await getBoothTemperature(boothIdx)

    if (getBoothTemperatureResponse.code !== '20') {
      alert(getBoothTemperatureResponse.message)
      return
    }
    setBoothTemperatureResponse(getBoothTemperatureResponse.data)
    // console.log(getBoothTemperatureResponse.data)
  }

  // [BoothAirQualityApi]
  const [boothAirQualityResponse, setBoothAirQualityResponse] = useState({})
  const getBoothAirQualityApi = async (boothIdx) => {
    const getBoothAirQualityResponse = await getBoothAirQuality(boothIdx)

    if (getBoothAirQualityResponse.code !== '20') {
      alert(getBoothAirQualityResponse.message)
      return
    }
    setBoothAirQualityResponse(getBoothAirQualityResponse.data)
    // console.log(getBoothAirQualityResponse.data)
  }

  // [Visitor]
  const [visitorData, setVisitorData] = useState([])
  const [visitorLabels, setVisitorLabels] = useState([])
  const getBoothVisitorApi = async (boothIdx) => {
    const getBoothVisitorResponse = await getBoothVisitor(boothIdx)

    if (getBoothVisitorResponse.code !== '20') {
      alert(getBoothVisitorResponse.message)
      return
    }

    setVisitorData(getBoothVisitorResponse.data.count)
    setVisitorLabels(getBoothVisitorResponse.data.createdAt)
    // console.log(getBoothVisitorResponse.data)
  }

  useEffect(() => {
    getBoothListApi()

    // eslint-disable-next-line
  }, [])

  const selectChanged = (e) => {
    setSelectedBooth(e.target.value)
    getBoothTemperatureApi(e.target.value)
    getBoothAirQualityApi(e.target.value)
    getBoothVisitorApi(e.target.value)
    // getBoothAirQualityApi(e.target.value)
    localStorage.setItem('selectedBoothIdx', e.target.value)
  }

  return (
    <div className='h-screen'>
      <Header />
      <div className='flex h-[calc(100%-60px)]'>
        <SideMenuBar />
        <div className='flex flex-col w-full h-full p-40 overflow-y-auto gap-30'>
          <p className='text-black text-text24'>부스 운영현황</p>
          <select
            value={selectedBooth}
            className='w-[250px] px-20 py-[15px] border border-gray bg-no-repeat bg-[center_right_20px]'
            style={{ backgroundImage: 'url(' + DropDownSVG + ')' }}
            onChange={(e) => selectChanged(e)}
          >
            {boothListResponse.map((boothItem) => (
              <option key={`${boothItem.idx}`} value={`${boothItem.idx}`}>
                {boothItem.name}
              </option>
            ))}
          </select>

          {/* 표 */}
          <div className='flex flex-col gap-20'>
            {/* 1: 부스 내 온도 현황 */}
            <div className='flex flex-col w-full gap-20 p-20 border border-border'>
              <p className='text-text20 text-primary'>부스 내 온도 현황</p>
              <div>
                <div className='flex items-center gap-[100px]'>
                  <div className='w-[280px] flex flex-col gap-12'>
                    <p className='text-text16 text-text-strong'>오후 2시 기준</p>
                    <div className='w-[135px] shrink-0 flex flex-col gap-20 px-[22px] py-10'>
                      <div className='flex justify-center'>
                        <img src={ClimateCelsiusSVG} alt='' className='w-60 h-60' />
                      </div>
                      <div className='flex flex-col items-center justify-center gap-10'>
                        <div className='px-10 bg-green'>
                          <p className='text-white text-text16'>양호</p>
                        </div>
                        <p className='text-text20 text-primary'>
                          {boothTemperatureResponse.celsius?.[
                            boothTemperatureResponse.celsius?.length - 1
                          ] || 'N/A'}
                          °C
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='w-full h-[280px]'>
                    <LineChart
                      // key={reRender}
                      graphData={{
                        labels: boothTemperatureResponse.createdAt,
                        datasets: [
                          {
                            label: '온도',
                            data: boothTemperatureResponse.celsius,
                            // backgroundColor: 'rgba(255, 99, 99, 1)', // 포인트 배경색
                            borderColor: 'rgba(13, 36, 66, 1)',
                            // borderWidth: 3,
                            // pointRadius: 4, // 포인트 크기
                            // pointBorderWidth: 2,
                          },
                        ],
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* 2: 부스 내 공기질 현황 */}
            <div className='flex flex-col w-full gap-20 p-20 border border-border'>
              <p className='text-text20 text-primary'>부스 내 공기질 현황</p>
              <div>
                <div className='flex items-center gap-[30px]'>
                  <div className='w-[280px] flex flex-col gap-12'>
                    <p className='text-text16 text-text-strong'>오후 2시 기준</p>
                    <div className='flex justify-center gap-6 divide-x divide-border'>
                      {/* CO */}
                      <div className='shrink-0 flex flex-col gap-20 px-[22px] py-10'>
                        <div className='flex justify-center'>
                          <img
                            src={require('assets/images/airquality-co.png')}
                            alt=''
                            className='w-60 h-60'
                          />
                        </div>
                        <div className='flex flex-col items-center justify-center gap-10'>
                          <div
                            className='px-10'
                            style={{
                              backgroundColor:
                                boothAirQualityResponse.coStatusColor?.[
                                  boothAirQualityResponse.coStatusColor?.length - 1
                                ] || 'N/A',
                            }}
                          >
                            <p className='text-white text-text16'>
                              {boothAirQualityResponse.coStatus?.[
                                boothAirQualityResponse.coStatus?.length - 1
                              ] || 'N/A'}
                            </p>
                          </div>
                          <p className='text-text20 text-primary'>
                            {boothAirQualityResponse.co?.[boothAirQualityResponse.co?.length - 1] ||
                              'N/A'}
                            ppm
                          </p>
                        </div>
                      </div>

                      {/* CO2 */}
                      <div className='shrink-0 flex flex-col gap-20 px-[22px] py-10'>
                        <div className='flex justify-center'>
                          <img
                            src={require('assets/images/airquality-co2.png')}
                            alt=''
                            className='w-60 h-60'
                          />
                        </div>
                        <div className='flex flex-col items-center justify-center gap-10'>
                          <div
                            className='px-10'
                            style={{
                              backgroundColor:
                                boothAirQualityResponse.co2StatusColor?.[
                                  boothAirQualityResponse.co2StatusColor?.length - 1
                                ] || 'N/A',
                            }}
                          >
                            <p className='text-white text-text16'>
                              {boothAirQualityResponse.co2Status?.[
                                boothAirQualityResponse.co2Status?.length - 1
                              ] || 'N/A'}
                            </p>
                          </div>
                          <p className='text-text20 text-primary'>
                            {boothAirQualityResponse.co2?.[
                              boothAirQualityResponse.co2?.length - 1
                            ] || 'N/A'}
                            ppm
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='flex flex-col w-full'>
                    <div className='w-full h-[140px] px-10'>
                      <BarChartCo
                        graphData={{
                          labels: ['Co'],
                          datasets: [
                            {
                              label: '공기질',
                              data: [
                                boothAirQualityResponse.co?.[
                                  boothAirQualityResponse.co?.length - 1
                                ],
                              ], // CO 데이터
                              backgroundColor: 'rgba(63, 191, 3, 1)',
                              borderColor: 'rgba(13, 36, 66, 1)',
                              xAxisID: 'x',
                            },
                          ],
                        }}
                      />
                    </div>
                    <div className='w-full h-[140px]'>
                      <BarChartCo2
                        graphData={{
                          labels: ['Co2'],
                          datasets: [
                            {
                              label: '공기질',
                              data: [
                                boothAirQualityResponse.co2?.[
                                  boothAirQualityResponse.co2?.length - 1
                                ],
                              ], // CO2 데이터
                              backgroundColor: 'rgba(255, 146, 77, 1)',
                              borderColor: 'rgba(13, 36, 66, 1)',
                              xAxisID: 'x',
                            },
                          ],
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* 3: 부스 사용인원 현황 */}
            <div className='flex flex-col w-full gap-20 p-20 border border-border'>
              <p className='text-text20 text-primary'>부스 사용인원 현황</p>
              <div>
                <div className='flex items-center gap-[100px]'>
                  <div className='w-[280px] flex flex-col gap-12'>
                    <p className='text-text16 text-text-strong'>오후 1:30 ~ 2:00 기준</p>
                    <div className='w-[135px] shrink-0 flex flex-col items-center gap-20 px-[22px] py-10'>
                      <div className='flex justify-center'>
                        <img src={UserMultipleSVG} alt='' className='w-60 h-60' />
                      </div>

                      <p className='text-text20 text-primary'>
                        {visitorData[visitorData.length - 1]}명
                      </p>
                    </div>
                  </div>
                  <div className='w-full h-[280px]'>
                    <LineChart
                      // key={reRender}
                      graphData={{
                        labels: visitorLabels,
                        datasets: [
                          {
                            label: '이용자수',
                            data: visitorData,
                            // backgroundColor: 'rgba(255, 99, 99, 1)', // 포인트 배경색
                            borderColor: 'rgba(13, 36, 66, 1)',
                            // borderWidth: 3,
                            // pointRadius: 4, // 포인트 크기
                            // pointBorderWidth: 2,
                          },
                        ],
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
