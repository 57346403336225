import { useEffect, useState, useRef } from 'react'
import { useForm } from 'react-hook-form'

import Close from 'assets/icons/close.svg'
import DropDownSVG from 'assets/icons/chevron-down.svg'

import { postAdminAdd } from 'apis/adminadd.ts'

const authorityList = ['관리자 관리', '부스 제어', '부스 관리', '설정 관리', '원격 지원']

export default function ModalAddAdministrator({ refresh, setIsAddAdministratorModalOpen }) {
  const modalBackground = useRef()

  // react-hook-form
  const { register, handleSubmit, watch, setValue, trigger } = useForm()
  // const watchAll = watch()
  // console.log(watchAll)

  // email2, email3 항목 동기화
  const watchEmail3 = watch('email3')

  const isFirstRender = useRef(true)
  useEffect(() => {
    setValue('email2', watchEmail3 === 'defaultValue' ? '' : watchEmail3)
    if (!isFirstRender.current) {
      trigger('email2')
    } else {
      isFirstRender.current = false
    }
  }, [watchEmail3, setValue, trigger])

  const onSubmit = async (data) => {
    const permissionData = {
      admin_manage: checkedItems.includes('관리자 관리'),
      booth_manage: checkedItems.includes('부스 제어'),
      booth_control: checkedItems.includes('부스 관리'),
      remote_support: checkedItems.includes('설정 관리'),
      setting_manage: checkedItems.includes('원격 관리'),
    }
    const permissionJson = JSON.stringify(permissionData)

    let body = {
      name: data.name,
      id: data.id,
      password: data.password,
      phoneNumber: data.telNum1 + '-' + data.telNum2 + '-' + data.telNum3,
      email: data.email1 + '@' + data.email2,
      permission: permissionJson,
    }

    const postAdminAddResponse = await postAdminAdd(body)

    if (postAdminAddResponse.code !== '20') {
      alert(postAdminAddResponse.message)
      return
    }
    setIsAddAdministratorModalOpen((prev) => !prev)
    refresh()
  }

  // 체크박스 아이템 상태관리
  const [checkedItems, setCheckedItems] = useState([])

  // 개별 체크박스 상태 업데이트 함수
  const handleCheck = (id) => {
    setCheckedItems((prev) => {
      // 체크 해제 시 상태에서 제거
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id)
      }
      // 체크 시 상태에 추가
      else {
        return [...prev, id]
      }
    })
  }

  return (
    <div className='fixed top-0 left-0 z-50 w-screen h-screen cursor-default'>
      {/* Background of Modal */}
      <div
        className='absolute w-full h-full bg-black/40'
        ref={modalBackground}
        onClick={(e) => {
          if (e.target === modalBackground.current) setIsAddAdministratorModalOpen((prev) => !prev)
        }}
      />
      {/* Modal */}
      <div className='absolute w-[1280px] transform -translate-x-1/2 -translate-y-1/2 bg-white top-1/2 left-1/2 '>
        <form onSubmit={handleSubmit(onSubmit)} className='pt-[50px] flex flex-col gap-40'>
          <div className='px-[50px] flex justify-between'>
            <p className='text-text24'>관리자 추가</p>
            <button
              className='flex items-center'
              onClick={() => setIsAddAdministratorModalOpen((prev) => !prev)}
            >
              <img src={Close} alt='' />
            </button>
          </div>

          <div className='px-[50px]'>
            {/* 권한 */}
            <div className='flex'>
              <label className='w-[200px] shrink-0 px-20 py-[23px] bg-gray border-t border-t-border'>
                <p className='text-text16'>권한</p>
              </label>
              <div className='w-full px-20 py-8 bg-white border-t border-t-gray'>
                <div className='grid grid-cols-3 gap-20'>
                  {authorityList.map((authorityItem, index) => (
                    <div key={index} className='flex gap-20'>
                      <div className='flex items-center justify-center px-12'>
                        <label
                          htmlFor=''
                          className='w-20 h-20 bg-white bg-center bg-no-repeat bg-cover border shrink-0 border-border'
                        >
                          <input
                            id={`${index}`}
                            type='checkbox'
                            checked={checkedItems.includes(authorityItem)} // 체크된 상태 확인
                            onChange={() => handleCheck(authorityItem)} // 개별 체크박스 선택/해제
                            className='w-20 h-20 bg-center bg-no-repeat appearance-none'
                          />
                        </label>
                      </div>
                      <label htmlFor={`${index}`}>{authorityItem}</label>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* 이름 */}
            <div className='flex'>
              <label className='w-[200px] shrink-0 px-20 py-[23px] bg-gray border-t border-t-border'>
                <p className='text-text16'>이름</p>
              </label>
              <div className='w-full px-20 py-8 bg-white border-t border-t-gray'>
                <input
                  {...register('name')}
                  type='text'
                  placeholder='이름을 입력해주세요'
                  className='w-full px-16 py-16 border text-text14 text-text-sub border-gray'
                />
              </div>
            </div>

            {/* 아이디 */}
            <div className='flex'>
              <label className='w-[200px] shrink-0 px-20 py-[23px] bg-gray border-t border-t-border'>
                <p className='text-text16'>아이디</p>
              </label>
              <div className='w-full px-20 py-8 bg-white border-t border-t-gray'>
                <input
                  {...register('id')}
                  type='text'
                  placeholder='아이디를 입력해주세요'
                  className='w-full px-16 py-16 border text-text14 text-text-sub border-gray'
                />
              </div>
            </div>

            {/* 아이디 */}
            <div className='flex'>
              <label className='w-[200px] shrink-0 px-20 py-[23px] bg-gray border-t border-t-border'>
                <p className='text-text16'>비밀번호</p>
              </label>
              <div className='w-full px-20 py-8 bg-white border-t border-t-gray'>
                <input
                  {...register('password')}
                  type='text'
                  placeholder='비밀번호를 입력해주세요'
                  className='w-full px-16 py-16 border text-text14 text-text-sub border-gray'
                />
              </div>
            </div>

            {/* 대표 전화번호 */}
            <div className='flex'>
              <label className='w-[200px] shrink-0 px-20 py-[23px] bg-gray border-t border-t-border'>
                <p className='text-text16'>대표 전화번호</p>
              </label>
              <div className='flex items-center w-full gap-12 px-20 py-8 bg-white border-t border-t-gray'>
                {/* 전화번호1 */}
                <select
                  {...register('telNum1')}
                  className='w-[250px] px-20 py-[15px] border border-gray bg-no-repeat bg-[center_right_20px]'
                  style={{ backgroundImage: 'url(' + DropDownSVG + ')' }}
                >
                  <option value='02'>02</option>
                  <option value='031'>031</option>
                  <option value='010'>010</option>
                  <option value='011'>011</option>
                </select>
                <p className='text-text16 text-text-strong'>-</p>
                {/* 전화번호2 */}
                <input
                  {...register('telNum2')}
                  type='text'
                  className='w-[250px] px-20 py-[15px] border border-gray'
                />
                <p className='text-text16 text-text-strong'>-</p>
                {/* 전화번호3 */}
                <input
                  {...register('telNum3')}
                  type='text'
                  className='w-[250px] px-20 py-[15px] border border-gray'
                />
              </div>
            </div>

            {/* 이메일 */}
            <div className='flex'>
              <label className='w-[200px] shrink-0 px-20 py-[23px] bg-gray border-t border-t-border'>
                <p className='text-text16'>이메일</p>
              </label>
              <div className='flex items-center w-full gap-12 px-20 py-8 bg-white border-t border-t-gray'>
                {/* 이메일1 */}
                <input
                  {...register('email1')}
                  type='text'
                  className='w-[250px] px-20 py-[15px] border border-gray'
                />

                <p className='text-text16 text-text-strong'>@</p>
                {/* 이메일2 */}
                <input
                  {...register('email2')}
                  type='text'
                  className='w-[250px] px-20 py-[15px] border border-gray'
                />

                {/* 이메일3 */}
                <select
                  {...register('email3')}
                  className='w-[250px] px-20 py-[15px] border border-gray bg-no-repeat bg-[center_right_20px]'
                  style={{ backgroundImage: 'url(' + DropDownSVG + ')' }}
                >
                  <option value=''>직접입력</option>
                  <option value='naver.com'>naver.com</option>
                  <option value='google.com'>google.com</option>
                  <option value='daum.net'>daum.net</option>
                  <option value='outlook.coms'>outlook.coms</option>
                </select>
              </div>
            </div>
          </div>

          <button type='submit' className='w-full btn-primary_large px-[185px] py-[15px] '>
            <p>추가하기</p>
          </button>
        </form>
      </div>
    </div>
  )
}
