import { CommonResponse, get } from './common.ts'

// GET /admin/profile
interface GetUserProfileResponse {
  idx: number
  authority: string
  name: string
  id: string
  phoneNumber: string
  email: string
  updatedAt: string
  updatedBy: string
  createdAt: string
}

export const getUserProfile = async (): Promise<CommonResponse<GetUserProfileResponse>> => {
  return await get('/admin/profile')
}
