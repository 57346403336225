import { CommonResponse, get } from './common.ts'

// GET v1/admin/list?page=페이지번호

interface GetAdminListRespone {
  admins: {
    idx: number
    authority: string
    name: string
    id: string
    phoneNumber: string
    email: string
    updatedAt: string
    updatedBy: string
    createdAt: string
    permission: string
  }[]
  maxPage: number
  currentPageElementNumber: number
}

export const getAdminList = async (
  pageNum: number
): Promise<CommonResponse<GetAdminListRespone>> => {
  return await get(`/admin/list?page=${pageNum}`)
}
