import { CommonResponse, get } from './common.ts'

// GET /weather
interface GetWeatherResponse {
  temperature: number
  humidity: number
  windDirection: string
  windSpeed: number
  temperatureStatus: string
  humidityStatus: string
  windSpeedStatus: string
  temperatureStatusColor: string
  humidityStatusColor: string
  windSpeedStatusColor: string
}

export const getWeather = async (): Promise<CommonResponse<GetWeatherResponse>> => {
  return await get('/weather')
}
