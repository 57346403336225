import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { useEffect } from 'react'

import Login from './views/Login'
import Member from './views/Member'
import MemberBoothRegistration from 'views/MemberBoothRegistration'
import BoothManagement from './views/BoothManagement'
import BoothManagementControl from 'views/BoothManagementControl'
import BoothManagementOperationStatus from 'views/BoothManagementOperationStatus'
import BoothManagementAirquality from 'views/BoothManagementAirquality'

import { RecoilRoot, useRecoilState } from 'recoil'
import loginState from 'states/loginState.ts'

// api
import { getUserProfile } from 'apis/profile.ts'

function RequireAuth({ children, redirectTo }) {
  let locate = useLocation()
  console.log('RequireAuth ' + locate.pathname)

  const [login, setLogin] = useRecoilState(loginState)
  console.log(login)

  useEffect(() => {
    async function checkLogin() {
      let profileRes = await getUserProfile()
      if (profileRes.code !== '20') {
        setLogin({ isLoggedIn: false })
      } else {
        setLogin({
          isLoggedIn: true,
        })
      }
    }

    checkLogin()
  }, [setLogin])

  if (login.isLoggedIn === true) {
    return children
  } else if (login.isLoggedIn === false) {
    return <Navigate to={redirectTo} />
  } else {
    return <></>
  }
}

function NoRequireAuth({ children, redirectTo }) {
  let locate = useLocation()
  console.log('NoRequireAuth ' + locate.pathname)

  const [login, setLogin] = useRecoilState(loginState)
  console.log(login)

  useEffect(() => {
    async function checkLogin() {
      let profileRes = await getUserProfile()
      if (profileRes.code !== '20') {
        setLogin({ isLoggedIn: false })
      } else {
        setLogin({
          isLoggedIn: true,
        })
      }
    }

    checkLogin()
  }, [setLogin])

  if (login.isLoggedIn === false) {
    return children
  } else if (login.isLoggedIn === true) {
    return <Navigate to={redirectTo} />
  } else {
    return <></>
  }
}

function App() {
  return (
    <RecoilRoot>
      <div className='App'>
        <Router>
          <Routes>
            <Route
              path='/'
              element={
                <RequireAuth redirectTo={'/login'}>
                  <Navigate to='/member' />
                </RequireAuth>
              }
            ></Route>

            <Route
              path='/login'
              element={
                <NoRequireAuth redirectTo={'/member'}>
                  <Login />
                </NoRequireAuth>
              }
            ></Route>

            <Route
              path='/member'
              element={
                <RequireAuth redirectTo={'/login'}>
                  <Member />
                </RequireAuth>
              }
            ></Route>

            <Route
              path='/member/booth-registration'
              element={
                <RequireAuth redirectTo={'/login'}>
                  <MemberBoothRegistration />
                </RequireAuth>
              }
            ></Route>

            <Route
              path='/boothmanagement'
              element={
                <RequireAuth redirectTo={'/login'}>
                  <BoothManagement />
                </RequireAuth>
              }
            ></Route>

            <Route
              path='/boothmanagement/control'
              element={
                <RequireAuth redirectTo={'/login'}>
                  <BoothManagementControl />
                </RequireAuth>
              }
            ></Route>

            <Route
              path='/boothmanagement/operation-status'
              element={
                <RequireAuth redirectTo={'/login'}>
                  <BoothManagementOperationStatus />
                </RequireAuth>
              }
            ></Route>
            <Route
              path='/boothmanagement/airquality'
              element={
                <RequireAuth redirectTo={'/login'}>
                  <BoothManagementAirquality />
                </RequireAuth>
              }
            ></Route>
          </Routes>
        </Router>
      </div>
    </RecoilRoot>
  )
}

export default App
