import { CommonResponse, post } from './common.ts'

// POST /admin/login

interface PostUserLoginRequest {
  id: string
  password: string
}

export const postUserLogin = async (body: PostUserLoginRequest): Promise<CommonResponse<null>> => {
  return await post('/admin/login', body)
}
