import { CommonResponse, get } from './common.ts'

// GET v1/booth/{idx}/temperature

interface GetBoothTemperatureResponse {
  celsius: number[]
  time: string[]
}

export const getBoothTemperature = async (
  idx: number
): Promise<CommonResponse<GetBoothTemperatureResponse>> => {
  return await get(`/booth/${idx}/temperature`)
}
