import { CommonResponse, get } from './common.ts'

// GET v1/booth/{idx}/ventilator

interface GetBoothVentilatorResponse {
  usedStage: number
  isActive: boolean
  useStatus: string
  useStatusColor: string
}

export const getBoothVentilator = async (
  idx: number
): Promise<CommonResponse<GetBoothVentilatorResponse>> => {
  return await get(`/booth/${idx}/ventilator`)
}
