import { useState } from 'react'
import { useForm } from 'react-hook-form'

import LoginLogo from 'assets/logos/logo-login.svg'

import { useSetRecoilState } from 'recoil'
import loginState from 'states/loginState.ts'

// api
import { postUserLogin } from 'apis/login.ts'

export default function Login() {
  const setLogin = useSetRecoilState(loginState)

  const [isLoginFailed, setIsLoginFailed] = useState(false)
  const { register, handleSubmit, reset } = useForm()

  const onSubmit = async (data) => {
    // LoginApi
    const postUserLoginResponse = await postUserLogin({
      id: data.id,
      password: data.password,
    })

    if (postUserLoginResponse.code !== '20') {
      alert(postUserLoginResponse.code)

      setIsLoginFailed(true)
      reset()
      return
    }
    setLogin({ isLoggedIn: true })
    // navigate('/member', { replace: true })
  }
  console.log('Login')
  return (
    <div className='flex items-center justify-center w-screen h-screen bg-gray '>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='flex flex-col gap-[50px]'>
          {/* 굿스페이스 로고 */}
          <div className='flex justify-center'>
            <img src={LoginLogo} alt='' className='w-[61px] h-[51px]' />
          </div>

          {/* 아이디/비밀번호 */}
          <div className='flex flex-col gap-20'>
            {/* 아이디 */}
            <div className='flex flex-col gap-12'>
              <p className='text-[16px] text-text font-bold leading-[24px] tracking-[-0.4px]'>
                아이디
              </p>
              <input
                {...register('id')}
                type='text'
                className='w-[402px] h-[60px] flex shrink-0 bg-white pl-20 py-[18px] text-[16px] text-text-sub font-medium leading-[24px] tracking-[-0.4px]'
                placeholder='아이디를 입력해주세요'
              />
            </div>

            {/* 비밀번호 */}
            <div className='flex flex-col gap-12'>
              <p className='text-[16px] text-text font-bold leading-[24px] tracking-[-0.4px]'>
                비밀번호
              </p>
              <input
                {...register('password')}
                type='password'
                className='w-[402px] h-[60px] flex shrink-0 bg-white pl-20 py-[18px] text-[16px] text-text-sub font-medium leading-[24px] tracking-[-0.4px]'
                placeholder='비밀번호를 입력해주세요'
              />
            </div>
          </div>

          {/* 로그인 */}
          <div className='flex flex-col gap-[10px]'>
            <p className={`${isLoginFailed ? 'block' : 'hidden'} text-text14 text-center text-red`}>
              로그인 정보가 틀렸습니다
            </p>
            <button type='submit' className='btn-primary_large'>
              로그인
            </button>
            <p className='text-center text-text14 text-text-sub'>계정관리 02-1234-5678</p>
          </div>
        </div>
      </form>
    </div>
  )
}
