import { CommonResponse, post } from './common.ts'

// POST v1/admin/delete

interface PostAdminDeleteRequest {
  idx: number
}

export const postAdminDelete = async (
  body: PostAdminDeleteRequest
): Promise<CommonResponse<null>> => {
  return await post('/admin/delete', body)
}
