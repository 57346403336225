import { CommonResponse, post } from './common.ts'

// POST v1/admin/add

interface PostAdminAddRequest {
  name: string
  id: string
  password: string
  phoneNumber: string
  email: string
  permission: JSON
}

export const postAdminAdd = async (body: PostAdminAddRequest): Promise<CommonResponse<null>> => {
  return await post('/admin/add', body)
}
