import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Header from 'components/Header'
import SideMenuBar from 'components/SideMenuBar'
import LineChart from 'components/LineChart'
import DoughnutChart from 'components/DoughnutChart'

import DropDownSVG from 'assets/icons/chevron-down.svg'
import ClimateCelsius from 'assets/icons/climate-celsius.svg'
import ClimateHumidity from 'assets/icons/climate-humidity.svg'
import ClimateWindy from 'assets/icons/climate-windy_strong.svg'

//api 함수
import { getWeather } from 'apis/weather.ts'
import { getBoothList } from 'apis/boothlist.ts'
import { getBoothAirQuality } from 'apis/airquality.ts'
import { getBoothVentilator } from 'apis/ventilator.ts'
import { GetBoothAriconditioner } from 'apis/airconditioner.ts'
import { GetBoothElectricPower } from 'apis/electricpower.ts'
import { getBoothVisitor } from 'apis/visitor.ts'

// const userData = [1, 1.5, 2.6, 3.7, 3, 1.2, 0, 0, 4.5, 3.5, 4.2]

// const labels = [
//   '09:30',
//   '09:35',
//   '09:40',
//   '09:45',
//   '09:50',
//   '09:55',
//   '10:00',
//   '10:05',
//   '10:10',
//   '10:15',
//   '10:20',
// ]

export default function BoothManagement() {
  const navigate = useNavigate()

  // selectedBooth: 현재 선택된 부스의 idx
  const [selectedBooth, setSelectedBooth] = useState(localStorage.getItem('selectedBoothIdx') || 0)

  // [BoothListApi]
  const [boothListResponse, setBoothListResponse] = useState([])
  const getBoothListApi = async () => {
    const getBoothListResponse = await getBoothList()

    if (getBoothListResponse.code !== '20') {
      alert(getBoothListResponse.message)
      navigate('/member/booth-registration')
      return
    }

    setBoothListResponse(getBoothListResponse.data)
    // console.log(getBoothListResponse.data)
    let firstBooth = getBoothListResponse.data[0].idx
    // console.log(firstBooth)

    setSelectedBooth(firstBooth)
    getBoothAirQualityApi(firstBooth)
    getBoothVentilatorApi(firstBooth)
    getBoothAirConditionerApi(firstBooth)
    getBoothElectricPowerApi(firstBooth)
    getBoothVisitorApi(firstBooth)
  }

  // [WeatherApi]
  const [weatherResponse, setWeatherResponse] = useState({})
  const getWeatherApi = async () => {
    const getWeatherResponse = await getWeather()

    if (getWeatherResponse.code !== '20') {
      alert(getWeatherResponse.message)
      return
    }
    setWeatherResponse(getWeatherResponse.data)
    // console.log(getWeatherResponse.data)
  }

  // [BoothAirQualityApi]
  const [boothAirQualityResponse, setBoothAirQualityResponse] = useState({})
  const getBoothAirQualityApi = async (boothIdx) => {
    const getBoothAirQualityResponse = await getBoothAirQuality(boothIdx)

    if (getBoothAirQualityResponse.code !== '20') {
      alert(getBoothAirQualityResponse.message)
      return
    }
    setBoothAirQualityResponse(getBoothAirQualityResponse.data)
    // console.log(getBoothAirQualityResponse.data)
  }

  // [VentilatorApi]
  const [ventilatorResponse, setVentilatorResponse] = useState({})
  const getBoothVentilatorApi = async (boothIdx) => {
    const getBoothVentilatorResponse = await getBoothVentilator(boothIdx)

    if (getBoothVentilatorResponse.code !== '20') {
      alert(getBoothVentilatorResponse.message)
      return
    }
    setVentilatorResponse(getBoothVentilatorResponse.data)
    // console.log(getBoothVentilatorResponse.data)
  }

  // [AriConditionerApi]
  const [airconditionerResponse, setAirconditionerResponse] = useState({})
  const getBoothAirConditionerApi = async (boothIdx) => {
    const getBoothAirConditionerResponse = await GetBoothAriconditioner(boothIdx)

    if (getBoothAirConditionerResponse.code !== '20') {
      alert(getBoothAirConditionerResponse.message)
      return
    }
    setAirconditionerResponse(getBoothAirConditionerResponse.data)
    // console.log(getBoothAirConditionerResponse.data)
  }

  // [ElectricPowerApi]
  const [electricPowerResponse, setElectricPowerResponse] = useState({})
  const getBoothElectricPowerApi = async (boothIdx) => {
    const getBoothElectricPowerResponse = await GetBoothElectricPower(boothIdx)

    if (getBoothElectricPowerResponse.code !== '20') {
      alert(getBoothElectricPowerResponse.message)
      return
    }
    setElectricPowerResponse(getBoothElectricPowerResponse.data)
    console.log(getBoothElectricPowerResponse.data)
  }

  // [Visitor]
  const [userData, setUserData] = useState([])
  const [labels, setLabels] = useState([])
  const getBoothVisitorApi = async (boothIdx) => {
    const getBoothVisitorResponse = await getBoothVisitor(boothIdx)

    if (getBoothVisitorResponse.code !== '20') {
      alert(getBoothVisitorResponse.message)
      return
    }

    setUserData(getBoothVisitorResponse.data.count)
    setLabels(getBoothVisitorResponse.data.createdAt)
    // console.log(getBoothVisitorResponse.data)
  }

  useEffect(() => {
    getBoothListApi()
    getWeatherApi()
    // eslint-disable-next-line
  }, [])

  const selectChanged = (e) => {
    setSelectedBooth(e.target.value)
    getBoothAirQualityApi(e.target.value)
    getBoothVentilatorApi(e.target.value)
    getBoothAirConditionerApi(e.target.value)
    getBoothElectricPowerApi(e.target.value)
    getBoothVisitorApi(e.target.value)
    localStorage.setItem('selectedBoothIdx', e.target.value)
  }

  return (
    <div className='h-screen'>
      <Header />
      <div className='flex h-[calc(100%-60px)]'>
        <SideMenuBar />
        <div className='flex flex-col w-full h-full p-40 overflow-y-auto gap-30'>
          <p className='text-black text-text24'>관리자 대시보드</p>
          <select
            value={selectedBooth}
            className='w-[250px] px-20 py-[15px] border border-gray bg-no-repeat bg-[center_right_20px]'
            style={{ backgroundImage: 'url(' + DropDownSVG + ')' }}
            onChange={(e) => selectChanged(e)}
          >
            {boothListResponse.map((boothItem) => (
              <option key={`${boothItem.idx}`} value={`${boothItem.idx}`}>
                {boothItem.name}
              </option>
            ))}
          </select>
          {/* 표 */}
          <div className='grid grid-cols-[3fr_2.8fr] gap-20'>
            <div className='grid w-full grid-rows-2 gap-20'>
              <div className='grid grid-cols-[4fr_3fr] gap-16'>
                {/* 기상 현황 */}
                <div className='flex flex-col gap-12 bg-white border p-30 border-border'>
                  <p className='text-text20 text-primary'>기상 현황</p>
                  <div className='flex justify-center gap-6 divide-x divide-border'>
                    {/* 기상 현황 - 온도 */}
                    <div className='w-[135px] shrink-0 flex flex-col gap-20 px-[22px] py-10'>
                      <div className='flex justify-center'>
                        <img src={ClimateCelsius} alt='' className='w-60 h-60' />
                      </div>
                      <div className='flex flex-col items-center justify-center gap-10'>
                        <div
                          className='px-10'
                          style={{ backgroundColor: weatherResponse.temperatureStatusColor }}
                        >
                          <p className='text-white text-text16'>
                            {weatherResponse.temperatureStatus}
                          </p>
                        </div>
                        <p className='text-text20 text-primary'>{weatherResponse.temperature}°C</p>
                      </div>
                    </div>

                    {/* 기상 현황 - 습도 */}
                    <div className='w-[135px] shrink-0 flex flex-col gap-20 px-[22px] py-10'>
                      <div className='flex justify-center'>
                        <img src={ClimateHumidity} alt='' className='w-60 h-60' />
                      </div>
                      <div className='flex flex-col items-center justify-center gap-10'>
                        <div
                          className='px-10 '
                          style={{ backgroundColor: weatherResponse.humidityStatusColor }}
                        >
                          <p className='text-white text-text16'>{weatherResponse.humidityStatus}</p>
                        </div>
                        <p className='text-text20 text-primary'>{weatherResponse.humidity}%</p>
                      </div>
                    </div>

                    {/* 기상 현황 - 바람 */}
                    <div className='w-[135px] shrink-0 flex flex-col gap-20 px-[22px] py-10'>
                      <div className='flex justify-center'>
                        <img src={ClimateWindy} alt='' className='w-60 h-60' />
                      </div>
                      <div className='flex flex-col items-center justify-center gap-10'>
                        <div
                          className='px-10 bg-red'
                          style={{ backgroundColor: weatherResponse.windSpeedStatusColor }}
                        >
                          <p className='text-white text-text16'>
                            {weatherResponse.windSpeedStatus}
                          </p>
                        </div>
                        <p className='text-text20 text-primary'>
                          {weatherResponse.windDirection} {weatherResponse.windSpeed}m/s
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* 부스 내부공기 질 현황 */}
                <div className='flex flex-col gap-12 bg-white border p-30 border-border'>
                  <p className='text-text20 text-primary'>부스 내부공기 질 현황</p>
                  <div className='flex justify-center gap-6 divide-x divide-border'>
                    {/* CO*/}
                    <div className='shrink-0 flex flex-col gap-20 px-[22px] py-10'>
                      <div className='flex justify-center'>
                        <img
                          src={require('assets/images/airquality-co.png')}
                          alt=''
                          className='w-60 h-60'
                        />
                      </div>
                      <div className='flex flex-col items-center justify-center gap-10'>
                        <div
                          className='px-10'
                          style={{
                            backgroundColor:
                              boothAirQualityResponse.coStatusColor?.[
                                boothAirQualityResponse.coStatusColor?.length - 1
                              ] || 'N/A',
                          }}
                        >
                          <p className='text-white text-text16'>
                            {boothAirQualityResponse.coStatus?.[
                              boothAirQualityResponse.coStatus?.length - 1
                            ] || 'N/A'}
                          </p>
                        </div>
                        <p className='text-text20 text-primary'>
                          {boothAirQualityResponse.co?.[boothAirQualityResponse.co?.length - 1] ||
                            'N/A'}
                          ppm
                        </p>
                      </div>
                    </div>

                    {/* CO2 */}
                    <div className='shrink-0 flex flex-col gap-20 px-[22px] py-10'>
                      <div className='flex justify-center'>
                        <img
                          src={require('assets/images/airquality-co2.png')}
                          alt=''
                          className='w-60 h-60'
                        />
                      </div>
                      <div className='flex flex-col items-center justify-center gap-10'>
                        <div
                          className='px-10'
                          style={{
                            backgroundColor:
                              boothAirQualityResponse.co2StatusColor?.[
                                boothAirQualityResponse.co2StatusColor?.length - 1
                              ] || 'N/A',
                          }}
                        >
                          <p className='text-white text-text16'>
                            {boothAirQualityResponse.co2Status?.[
                              boothAirQualityResponse.co2Status?.length - 1
                            ] || 'N/A'}
                          </p>
                        </div>
                        <p className='text-text20 text-primary'>
                          {boothAirQualityResponse.co2?.[boothAirQualityResponse.co2?.length - 1] ||
                            'N/A'}
                          ppm
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='grid grid-cols-[3fr_3fr_2fr] gap-16'>
                {/* 환풍기 출력 */}
                <div className='flex flex-col gap-12 bg-white border p-30 border-border'>
                  <p className='text-text20 text-primary'>환풍기 출력</p>
                  <div className='grid h-full grid-cols-2 gap-12 '>
                    <div className='flex flex-col items-center justify-center gap-10'>
                      <div
                        className='px-10 '
                        style={{ backgroundColor: ventilatorResponse.usedStatusColor }}
                      >
                        <p className='text-white text-text16'>{ventilatorResponse.usedStatus}</p>
                      </div>
                      <p className='text-text20 text-primary'>{ventilatorResponse.usedStage}단계</p>
                    </div>
                    {/* <div className='flex items-center'>
                      <img src={require('assets/images/usage1.png')} alt='' />
                    </div> */}
                    <div className=' w-[120px] h-[120px]'>
                      {/* 1단계 */}
                      {ventilatorResponse.usedStage === 1 && (
                        <DoughnutChart
                          graphData={{
                            labels: ['', ''],
                            datasets: [
                              {
                                data: [30, 70],
                                backgroundColor: ['rgba(13,37,66,255)', 'rgba(241,241,241,255)'],
                                borderWidth: 2,
                                radius: '100%',
                              },
                            ],
                          }}
                        />
                      )}
                      {/* 2단계 */}
                      {ventilatorResponse.usedStage === 2 && (
                        <DoughnutChart
                          graphData={{
                            labels: ['', ''],
                            datasets: [
                              {
                                data: [60, 40],
                                backgroundColor: ['rgba(13,37,66,255)', 'rgba(241,241,241,255)'],
                                borderWidth: 2,
                                radius: '100%',
                              },
                            ],
                          }}
                        />
                      )}
                      {/* 3단계 */}
                      {ventilatorResponse.usedStage === 3 && (
                        <DoughnutChart
                          graphData={{
                            labels: ['', ''],
                            datasets: [
                              {
                                data: [100, 0],
                                backgroundColor: ['rgba(13,37,66,255)', 'rgba(241,241,241,255)'],
                                borderWidth: 2,
                                radius: '100%',
                              },
                            ],
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>

                {/* 냉난방기 출력 */}
                <div className='flex flex-col gap-12 bg-white border p-30 border-border'>
                  <p className='text-text20 text-primary'>냉난방기 출력</p>
                  <div className='grid h-full grid-cols-2 gap-12 '>
                    <div className='flex flex-col items-center justify-center gap-10'>
                      <div
                        className='px-10'
                        style={{ backgroundColor: airconditionerResponse.usedStatusColor }}
                      >
                        <p className='text-white text-text16'>
                          {airconditionerResponse.usedStatus}
                        </p>
                      </div>
                      <p className='text-text20 text-primary'>
                        {airconditionerResponse.usedStage}단계
                      </p>
                    </div>
                    {/* <div className='flex items-center'>
                      <img src={require('assets/images/usage1.png')} alt='' />
                    </div> */}
                    <div className=' w-[120px] h-[120px]'>
                      {/* 1단계 */}
                      {airconditionerResponse.usedStage === 1 && (
                        <DoughnutChart
                          graphData={{
                            labels: ['', ''],
                            datasets: [
                              {
                                data: [30, 70],
                                backgroundColor: ['rgba(13,37,66,255)', 'rgba(241,241,241,255)'],
                                borderWidth: 2,
                                radius: '100%',
                              },
                            ],
                          }}
                        />
                      )}
                      {/* 2단계 */}
                      {airconditionerResponse.usedStage === 2 && (
                        <DoughnutChart
                          graphData={{
                            labels: ['', ''],
                            datasets: [
                              {
                                data: [60, 40],
                                backgroundColor: ['rgba(13,37,66,255)', 'rgba(241,241,241,255)'],
                                borderWidth: 2,
                                radius: '100%',
                              },
                            ],
                          }}
                        />
                      )}
                      {/* 3단계 */}
                      {airconditionerResponse.usedStage === 3 && (
                        <DoughnutChart
                          graphData={{
                            labels: ['', ''],
                            datasets: [
                              {
                                data: [100, 0],
                                backgroundColor: ['rgba(13,37,66,255)', 'rgba(241,241,241,255)'],
                                borderWidth: 2,
                                radius: '100%',
                              },
                            ],
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>

                {/* 전력 사용량 */}
                <div className='flex flex-col gap-20 bg-white border p-30 border-border'>
                  <p className='text-text20 text-primary'>전력 사용량</p>
                  <div className='flex justify-center mt-10'>
                    <img src={require('assets/images/usage2.png')} alt='' />
                  </div>
                  <p className='text-center text-text20 text-primary'>
                    {electricPowerResponse.kwh}kWh
                  </p>
                </div>
              </div>
            </div>

            {/* 시간대별 이용자 현황 */}
            <div className='w-full border p-30 border-border'>
              <p className='text-text20 text-primary'>시간대별 이용자 현황</p>
              <div className='flex items-center w-full h-full'>
                <div className='flex items-center w-[750px] h-[370px]'>
                  <LineChart
                    // key={reRender}
                    graphData={{
                      labels: labels,
                      datasets: [
                        {
                          label: '이용자수',
                          data: userData,
                          // backgroundColor: 'rgba(255, 99, 99, 1)', // 포인트 배경색
                          borderColor: 'rgba(13, 36, 66, 1)',
                          // borderWidth: 3,
                          // pointRadius: 4, // 포인트 크기
                          // pointBorderWidth: 2,
                        },
                      ],
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
