import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Legend,
  scales,
} from 'chart.js'
// [ 1. 차트 컴포넌트 ]
import { Line } from 'react-chartjs-2'

// [ 2. register ]
ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Legend, scales)

// [ 3. data ]
// data는 상위 컴포넌트로부터 가져옴
// data에 labels 활용O => labels가 y축 index / labels 활용 X => data 배열의 각각 원소를 {x: , y: } 형태의 객체로.

// [ 4. 다양한 options ]
const options = {
  responsive: true,
  maintainAspectRatio: false, // 가로 세로 비율 유지 안함

  plugins: {
    legend: {
      display: false,
      position: 'bottom',
      labels: {
        font: {
          size: 14,
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          color: '#E3E3E3',
        },
      },
    },
    title: {
      display: true,
      text: 'electricboard',
    },
  },
}

export default function LineChart({ graphData }) {
  // 컴포넌트 prop: data, type, options
  return <Line options={options} data={graphData} />
}
