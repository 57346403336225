import { CommonResponse, get } from './common.ts'

// GET v1/booth/{idx}/air-conditioner

interface GetBoothAriconditionerResponse {
  usedStage: number
  temperature: number
  Created: boolean
  isActive: boolean
  usedStatus: string
  usedStatusColor: string
  temperatureStatus: string
  temperatureStatusColor: string
}

export const GetBoothAriconditioner = async (
  idx: number
): Promise<CommonResponse<GetBoothAriconditionerResponse>> => {
  return await get(`/booth/${idx}/air-conditioner`)
}
