import { CommonResponse, post } from './common.ts'

// POST v1/booth/{idx}/air-conditioner/active

interface PostBoothAirconditionerActiveRequest {
  active: boolean
}

export const postBoothAirconditionerActive = async (
  idx: number,
  body: PostBoothAirconditionerActiveRequest
): Promise<CommonResponse<null>> => {
  return await post(`/booth/${idx}/air-conditioner/active`, body)
}
