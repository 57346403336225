import { useEffect, useState } from 'react'

import { useForm } from 'react-hook-form'

import Header from 'components/Header'
import SideMenuBar from 'components/SideMenuBar'
import DropDownSVG from 'assets/icons/chevron-down.svg'

// api
import { postBoothEnroll } from 'apis/boothEnroll.ts'
import { getUserProfile } from 'apis/profile.ts'

export default function MemberBoothRegistration() {
  // react-hook-form
  const { register, handleSubmit, reset } = useForm()
  // const watchAll = watch()
  // console.log(watchAll)

  const onSubmit = (data) => {
    const body = {
      company: data.company,
      name: data.name,
      address: data.address,
      telNumber: data.telNum1 + '-' + data.telNum2 + '-' + data.telNum3,
      createdAt: data.createdAt,
    }
    postBoothEnrollApi(body)
  }

  const postBoothEnrollApi = async (body) => {
    const postBoothEnrollResponse = await postBoothEnroll(body)

    if (postBoothEnrollResponse.code !== '20') {
      alert(postBoothEnrollResponse.message)
      return
    }
    // console.log(postBoothEnrollResponse.message)
    reset()
  }

  const [userName, setUserName] = useState('')

  // UserProfile api
  const getUserProfileApi = async () => {
    const getUserProfileResponse = await getUserProfile()

    if (getUserProfileResponse.code !== '20') {
      alert(getUserProfileResponse.message)
      return
    }
    setUserName(getUserProfileResponse.data.name)
  }
  useEffect(() => {
    getUserProfileApi()
  }, [])

  return (
    <div className='h-screen'>
      <Header />
      <div className='flex h-[calc(100%-60px)]'>
        <SideMenuBar />
        <form
          onSubmit={handleSubmit(onSubmit)}
          className='flex flex-col w-full h-full p-40 overflow-y-auto gap-30'
        >
          <p className='text-black text-text24'>부스 등록</p>
          <div>
            {/* 아이디 */}
            <div className='flex'>
              <div className='w-[200px] shrink-0 px-20 py-[23px] bg-gray border-t border-t-border'>
                <p className='text-text16'>아이디</p>
              </div>
              <div className='w-full px-20 py-[23px] border-t border-t-gray bg-white'>
                <p className='text-text16'>{userName}</p>
              </div>
            </div>

            {/* 기관명 */}
            <div className='flex'>
              <label className='w-[200px] shrink-0 px-20 py-[23px] bg-gray border-t border-t-border'>
                <p className='text-text16'>기관명</p>
              </label>
              <div className='w-full px-20 py-8 bg-white border-t border-t-gray'>
                <input
                  {...register('company')}
                  type='text'
                  placeholder='기관명을 입력해주세요'
                  className='w-full px-16 py-16 border text-text14 text-text-sub border-gray'
                />
              </div>
            </div>

            {/* 부스명 */}
            <div className='flex'>
              <label className='w-[200px] shrink-0 px-20 py-[23px] bg-gray border-t border-t-border'>
                <p className='text-text16'>부스명</p>
              </label>
              <div className='w-full px-20 py-8 bg-white border-t border-t-gray'>
                <input
                  {...register('name')}
                  type='text'
                  placeholder='부스명을 입력해주세요'
                  className='w-full px-16 py-16 border text-text14 text-text-sub border-gray'
                />
              </div>
            </div>

            {/* 주소 */}
            {/* <div className='flex'>
              <label className='w-[200px] shrink-0 px-20 py-[23px] bg-gray border-t border-t-border'>
                <p className='text-text16'>주소</p>
              </label>
              <div className='flex w-full gap-10 px-20 py-8 bg-white border-t border-t-gray'>
                <input
                  disabled
                  type='text'
                  placeholder={address}
                  className='w-full px-16 py-16 border text-text14 text-text-sub border-gray'
                />
                <button className='btn-primary_large w-[400px] py-[15px]'>
                  <p>주소 찾기</p>
                </button>
              </div>
            </div> */}
            <div className='flex'>
              <label className='w-[200px] shrink-0 px-20 py-[23px] bg-gray border-t border-t-border'>
                <p className='text-text16'>주소</p>
              </label>
              <div className='flex w-full gap-10 px-20 py-8 bg-white border-t border-t-gray'>
                <input
                  {...register('address')}
                  type='text'
                  placeholder='주소를 입력해주세요'
                  className='w-full px-16 py-16 border text-text14 text-text-sub border-gray'
                />
              </div>
            </div>

            {/* 대표 전화번호 */}
            <div className='flex'>
              <label className='w-[200px] shrink-0 px-20 py-[23px] bg-gray border-t border-t-border'>
                <p className='text-text16'>대표 전화번호</p>
              </label>
              <div className='flex items-center w-full gap-12 px-20 py-8 bg-white border-t border-t-gray'>
                {/* 전화번호1 */}
                <select
                  {...register('telNum1')}
                  className='w-[250px] px-20 py-[15px] border border-gray bg-no-repeat bg-[center_right_20px]'
                  style={{ backgroundImage: 'url(' + DropDownSVG + ')' }}
                >
                  <option value='02'>02</option>
                  <option value='031'>031</option>
                  <option value='010'>010</option>
                  <option value='011'>011</option>
                </select>
                <p className='text-text16 text-text-strong'>-</p>
                {/* 전화번호2 */}
                <input
                  {...register('telNum2')}
                  type='text'
                  className='w-[250px] px-20 py-[15px] border border-gray'
                />
                <p className='text-text16 text-text-strong'>-</p>
                {/* 전화번호3 */}
                <input
                  {...register('telNum3')}
                  type='text'
                  className='w-[250px] px-20 py-[15px] border border-gray'
                />
              </div>
            </div>

            {/* 설치일자 */}
            <div className='flex'>
              <label className='w-[200px] shrink-0 px-20 py-[23px] bg-gray border-t border-t-border'>
                <p className='text-text16'>설치일자</p>
              </label>
              <div className='w-full px-20 py-8 bg-white border-t border-t-gray'>
                <input
                  {...register('createdAt')}
                  type='date'
                  placeholder='설치일자를 선택해주세요'
                  className='w-full px-16 py-16 border text-text14 text-text-sub border-gray'
                />
              </div>
            </div>
          </div>

          {/* 부스 등록 버튼 */}
          <div className='flex justify-center w-full'>
            <button type='submit' className='btn-primary_large px-[170px] py-[15px]'>
              <p>부스 등록</p>
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
