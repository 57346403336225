import { CommonResponse, get } from './common.ts'

// GET v1/booth/{idx}/visitor

interface GetBoothVisitorResponse {
  count: number[]
  time: string[]
}

export const getBoothVisitor = async (
  idx: number
): Promise<CommonResponse<GetBoothVisitorResponse>> => {
  return await get(`/booth/${idx}/visitor`)
}
