import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Header from 'components/Header'
import SideMenuBar from 'components/SideMenuBar'
import ModalOperationControl from 'components/ModalOperationControl'

import DropDownSVG from 'assets/icons/chevron-down.svg'
import ClimateCelsius from 'assets/icons/climate-celsius.svg'

import { getWeather } from 'apis/weather.ts'
import { getBoothList } from 'apis/boothlist.ts'
import { getBoothVentilator } from 'apis/ventilator.ts'
import { postBoothVentilatorUsedStage } from 'apis/ventilatorUsedStage.ts'
import { getBoothAirQuality } from 'apis/airquality.ts'
import { GetBoothAriconditioner } from 'apis/airconditioner.ts'
import { postBoothAirconditionerTemperature } from 'apis/airconditionerTemperature.ts'
import { GetBoothElectricPower } from 'apis/electricpower.ts'
import { postBoothElectricPowerUsedStage } from 'apis/electricpowerUsedStage.ts'

export default function BoothManagementControl() {
  const navigate = useNavigate()

  const [selectedMode, setSelectedMode] = useState('')
  // 모달-가동중지 활성화 상태
  const [isOperationControlModalOpen, setIsOperationControlModalOpen] = useState(false)

  // selectedBooth: 현재 선택된 부스의 idx
  const [selectedBooth, setSelectedBooth] = useState(localStorage.getItem('selectedBoothIdx') || 0)
  // console.log(selectedBooth)

  // [BoothListApi]
  const [boothListResponse, setBoothListResponse] = useState([])
  const getBoothListApi = async () => {
    const getBoothListResponse = await getBoothList()

    if (getBoothListResponse.code !== '20') {
      alert(getBoothListResponse.message)
      navigate('/member/booth-registration')
      return
    }
    setBoothListResponse(getBoothListResponse.data)
    // console.log(getBoothListResponse.data)
    let firstBooth = getBoothListResponse.data[0].idx
    // console.log(firstBooth)
    setSelectedBooth(firstBooth)
    getBoothAirQualityApi(firstBooth)
    getBoothVentilatorApi(firstBooth)
    getBoothAirConditionerApi(firstBooth)
    getBoothElectricPowerApi(firstBooth)
  }

  // [WeatherApi]
  const [weatherResponse, setWeatherResponse] = useState({})
  const getWeatherApi = async () => {
    const getWeatherResponse = await getWeather()

    if (getWeatherResponse.code !== '20') {
      alert(getWeatherResponse.message)
      return
    }
    setWeatherResponse(getWeatherResponse.data)
    // console.log(getWeatherResponse.data)
  }

  // [VentilatorApi]
  const [ventilatorResponse, setVentilatorResponse] = useState({})
  const getBoothVentilatorApi = async (boothIdx) => {
    const getBoothVentilatorResponse = await getBoothVentilator(boothIdx)

    if (getBoothVentilatorResponse.code !== '20') {
      alert(getBoothVentilatorResponse.message)
      return
    }
    setVentilatorResponse(getBoothVentilatorResponse.data)
    // console.log(getBoothVentilatorResponse.data)
  }

  // [VentilatorUsedStageApi]
  const postBoothVentilatorUsedStageApi = async (boothIdx, stage) => {
    const postBoothVentilatorUsedStageResponse = await postBoothVentilatorUsedStage(boothIdx, {
      stage: stage,
    })
    if (postBoothVentilatorUsedStageResponse.code !== '20') {
      alert(postBoothVentilatorUsedStageResponse.message)
      return
    }

    // console.log(postBoothVentilatorUsedStageResponse.message)
  }

  // [BoothAirQualityApi]
  const [boothAirQualityResponse, setBoothAirQualityResponse] = useState({})
  const getBoothAirQualityApi = async (boothIdx) => {
    const getBoothAirQualityResponse = await getBoothAirQuality(boothIdx)

    if (getBoothAirQualityResponse.code !== '20') {
      alert(getBoothAirQualityResponse.message)
      return
    }
    setBoothAirQualityResponse(getBoothAirQualityResponse.data)
    // console.log(getBoothAirQualityResponse.data)
  }

  // [AriConditionerApi]
  const [airconditionerResponse, setAirconditionerResponse] = useState({})
  const getBoothAirConditionerApi = async (boothIdx) => {
    const getBoothAirConditionerResponse = await GetBoothAriconditioner(boothIdx)

    if (getBoothAirConditionerResponse.code !== '20') {
      alert(getBoothAirConditionerResponse.message)
      return
    }
    setAirconditionerResponse(getBoothAirConditionerResponse.data)
    // console.log(getBoothAirConditionerResponse.data)
  }

  // [AirConditionerTemperature]
  const [temperature, setTemperature] = useState('')
  const postBoothAirconditionerTemperatureApi = async (boothIdx, temperature) => {
    const postBoothAirconditionerTemperatureResponse = await postBoothAirconditionerTemperature(
      boothIdx,
      { temperature: temperature }
    )

    if (postBoothAirconditionerTemperatureResponse.code !== '20') {
      alert(postBoothAirconditionerTemperatureResponse.message)
      return
    }
    // console.log(postBoothAirconditionerTemperatureResponse.message)
  }

  // [ElectricPowerApi]
  const [electricPowerResponse, setElectricPowerResponse] = useState({})
  const getBoothElectricPowerApi = async (boothIdx) => {
    const getBoothElectricPowerResponse = await GetBoothElectricPower(boothIdx)

    if (getBoothElectricPowerResponse.code !== '20') {
      alert(getBoothElectricPowerResponse.message)
      return
    }
    setElectricPowerResponse(getBoothElectricPowerResponse.data)
    // console.log(getBoothElectricPowerResponse.data)
  }

  // [ElectricPowerUsedStageApi]
  const postBoothElectricPowerUsedStageApi = async (boothIdx, stage) => {
    const postBoothElectricPowerUsedStageResponse = await postBoothElectricPowerUsedStage(
      boothIdx,
      {
        stage: stage,
      }
    )
    if (postBoothElectricPowerUsedStageResponse.code !== '20') {
      alert(postBoothElectricPowerUsedStageResponse.message)
      return
    }

    // console.log(postBoothElectricPowerUsedStageResponse.message)
  }

  useEffect(() => {
    getBoothListApi()
    getWeatherApi()

    // eslint-disable-next-line
  }, [])

  const selectChanged = (e) => {
    setSelectedBooth(e.target.value)
    getBoothVentilatorApi(e.target.value)
    getBoothAirQualityApi(e.target.value)
    getBoothAirConditionerApi(e.target.value)
    getBoothElectricPowerApi(e.target.value)
    localStorage.setItem('selectedBoothIdx', e.target.value)
  }

  const operationStopClicked = (mode) => {
    setIsOperationControlModalOpen(true)
    setSelectedMode(mode)
  }

  const ventilatorUsedStageChanged = async (stage) => {
    await postBoothVentilatorUsedStageApi(selectedBooth, stage)
    await getBoothVentilatorApi(selectedBooth)
  }

  const airconditionerTemperatureChanged = async () => {
    await postBoothAirconditionerTemperatureApi(selectedBooth, temperature)
    await getBoothAirConditionerApi(selectedBooth)
  }

  const electricPowerUsedStageChanged = async (stage) => {
    await postBoothElectricPowerUsedStageApi(selectedBooth, stage)
    await getBoothElectricPowerApi(selectedBooth)
  }

  return (
    <div className='h-screen'>
      <Header />
      <div className='flex h-[calc(100%-60px)]'>
        <SideMenuBar />
        <div className='flex flex-col w-full h-full p-40 overflow-y-auto gap-30'>
          <p className='text-black text-text24'>부스 원격 제어창</p>
          <select
            value={selectedBooth}
            className='w-[250px] px-20 py-[15px] border border-gray bg-no-repeat bg-[center_right_20px]'
            style={{ backgroundImage: 'url(' + DropDownSVG + ')' }}
            onChange={(e) => selectChanged(e)}
          >
            {boothListResponse.map((boothItem) => (
              <option key={`${boothItem.idx}`} value={`${boothItem.idx}`}>
                {boothItem.name}
              </option>
            ))}
          </select>

          <div className='flex flex-col gap-20'>
            {/* 1: 환풍기 출력 제어 */}
            <div className='flex flex-col w-full gap-20 p-20 border border-border'>
              <p className='text-text20 text-primary'>환풍기 출력 제어</p>
              <div>
                <div className='flex justify-between'>
                  <div className='flex gap-[70px] justify-center items-center'>
                    {/* 단계 상태 */}
                    <div className='w-[220px] bg-secondary px-[72px] py-[50px] flex flex-col items-center gap-10'>
                      <p className='text-text24 text-primary'>{ventilatorResponse.usedStage}단계</p>
                      <p className='text-center text-text20 text-primary'>
                        부스
                        <br />
                        이용 중
                      </p>
                    </div>
                    {/* 환풍기 출력 설정 */}
                    <div className='flex flex-col gap-12'>
                      <div>
                        <p className='text-text16 text-text-strong'>환풍기 출력 설정</p>
                      </div>
                      <div className='flex flex-col gap-6'>
                        <button
                          className={`w-[338px] px-20 py-[13px] border border-border  ${
                            ventilatorResponse.usedStage === 1
                              ? 'cursor-default bg-primary text-white'
                              : 'cursor-pointer bg-white'
                          }`}
                          onClick={() => {
                            ventilatorResponse.usedStage !== 1 && ventilatorUsedStageChanged(1)
                          }}
                        >
                          <p className='text-text16'>1단계 (환풍기 off)</p>
                        </button>
                        <button
                          className={`w-[338px] px-20 py-[13px] border border-border  ${
                            ventilatorResponse.usedStage === 2
                              ? 'cursor-default bg-primary text-white'
                              : 'cursor-pointer bg-white'
                          }`}
                          onClick={() => {
                            ventilatorResponse.usedStage !== 2 && ventilatorUsedStageChanged(2)
                          }}
                        >
                          <p className='text-text16'>2단계 (환풍기 on, 75회/h)</p>
                        </button>
                        <button
                          className={`w-[338px] px-20 py-[13px] border border-border  ${
                            ventilatorResponse.usedStage === 3
                              ? 'cursor-default bg-primary text-white'
                              : 'cursor-pointer bg-white'
                          }`}
                          onClick={() => {
                            ventilatorResponse.usedStage !== 3 && ventilatorUsedStageChanged(3)
                          }}
                        >
                          <p className='text-text16'>3단계 (최대 출력, 100회/h)</p>
                        </button>
                      </div>
                    </div>
                    {/* 실시간 부스 내 공기질 현황 */}
                    <div className='flex flex-col gap-12'>
                      <p className='text-text16 text-text-strong'>실시간 부스 내 공기질 현황</p>
                      <div className='flex justify-center gap-6 divide-x divide-border'>
                        {/* CO */}
                        <div className='shrink-0 flex flex-col gap-20 px-[22px] py-10'>
                          <div className='flex justify-center'>
                            <img
                              src={require('assets/images/airquality-co.png')}
                              alt=''
                              className='w-60 h-60'
                            />
                          </div>
                          <div className='flex flex-col items-center justify-center gap-10'>
                            <div
                              className='px-10'
                              style={{
                                backgroundColor:
                                  boothAirQualityResponse.coStatusColor?.[
                                    boothAirQualityResponse.coStatusColor?.length - 1
                                  ] || 'N/A',
                              }}
                            >
                              <p className='text-white text-text16'>
                                {boothAirQualityResponse.coStatus?.[
                                  boothAirQualityResponse.coStatus?.length - 1
                                ] || 'N/A'}
                              </p>
                            </div>
                            <p className='text-text20 text-primary'>
                              {boothAirQualityResponse.co?.[
                                boothAirQualityResponse.co?.length - 1
                              ] || 'N/A'}
                              ppm
                            </p>
                          </div>
                        </div>

                        {/* CO2 */}
                        <div className='shrink-0 flex flex-col gap-20 px-[22px] py-10'>
                          <div className='flex justify-center'>
                            <img
                              src={require('assets/images/airquality-co2.png')}
                              alt=''
                              className='w-60 h-60'
                            />
                          </div>
                          <div className='flex flex-col items-center justify-center gap-10'>
                            <div
                              className='px-10'
                              style={{
                                backgroundColor:
                                  boothAirQualityResponse.co2StatusColor?.[
                                    boothAirQualityResponse.co2StatusColor?.length - 1
                                  ] || 'N/A',
                              }}
                            >
                              <p className='text-white text-text16'>
                                {boothAirQualityResponse.co2Status?.[
                                  boothAirQualityResponse.co2Status?.length - 1
                                ] || 'N/A'}
                              </p>
                            </div>
                            <p className='text-text20 text-primary'>
                              {boothAirQualityResponse.co2?.[
                                boothAirQualityResponse.co2?.length - 1
                              ] || 'N/A'}
                              ppm
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* 가동 버튼 */}
                  <div className='flex items-center'>
                    <button
                      className={`${
                        ventilatorResponse.active
                          ? 'btn-primary_large'
                          : 'btn-secondary_small w-[400px] h-[52px] py-[15px] bg-text-sub text-gray cursor-auto'
                      } `}
                      onClick={() => {
                        ventilatorResponse.active && operationStopClicked('ventilator')
                      }}
                    >
                      <p className='text-white text-text16'>가동중지</p>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* 2: 냉난방기 출력 제어 */}
            <div className='flex flex-col w-full gap-20 p-20 border border-border'>
              <p className='text-text20 text-primary'>냉난방기 출력 제어</p>
              <div>
                <div className='flex justify-between'>
                  <div className='flex gap-[70px] justify-center items-center'>
                    {/* 온도 */}
                    <div className='w-[220px] bg-secondary px-[72px] py-[50px] flex flex-col items-center gap-10'>
                      <p className='text-text24 text-primary'>
                        {airconditionerResponse.temperature}°C
                      </p>
                      <p className='text-center text-text20 text-primary'>냉방 설정</p>
                    </div>
                    {/* 환풍기 출력 설정 */}
                    <div className='flex flex-col gap-12'>
                      <div>
                        <p className='text-text16 text-text-strong'>냉난방 온도 제어</p>
                      </div>
                      <div className='flex flex-col gap-6'>
                        <input
                          type='text'
                          value={temperature}
                          placeholder='온도를 입력해주세요'
                          className='w-[338px] px-20 py-[13px] border border-border bg-white text-text14 text-text-sub'
                          onChange={(e) => setTemperature(e.target.value)}
                        />
                        <div className='flex justify-end'>
                          <button
                            className='px-16 py-8 btn-primary_small w-[100px]'
                            onClick={airconditionerTemperatureChanged}
                          >
                            <p>온도 설정</p>
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* 실시간 부스 내 온도 현황 */}
                    <div className='flex flex-col gap-12'>
                      <p className='text-text16 text-text-strong'>실시간 부스 내 온도 현황</p>
                      <div className='w-[135px] shrink-0 flex flex-col gap-20 px-[22px] py-10'>
                        <div className='flex justify-center'>
                          <img src={ClimateCelsius} alt='' className='w-60 h-60' />
                        </div>
                        <div className='flex flex-col items-center justify-center gap-10'>
                          <div
                            className='px-10'
                            style={{ backgroundColor: weatherResponse.temperatureStatusColor }}
                          >
                            <p className='text-white text-text16'>
                              {weatherResponse.temperatureStatus}
                            </p>
                          </div>
                          <p className='text-text20 text-primary'>
                            {weatherResponse.temperature}°C
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* 가동 버튼 */}

                  <div className='flex items-center'>
                    <button
                      className={`${
                        airconditionerResponse.active
                          ? 'btn-primary_large'
                          : 'btn-secondary_small w-[400px] h-[52px] py-[15px] bg-text-sub text-gray cursor-auto'
                      } `}
                      onClick={() => {
                        airconditionerResponse.active && operationStopClicked('airconditioner')
                      }}
                    >
                      <p className='text-white text-text16'>가동중지</p>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* 3: 전력 제어 */}
            <div className='flex flex-col w-full gap-20 p-20 border border-border'>
              <p className='text-text20 text-primary'>전력 제어</p>
              <div>
                <div className='flex justify-between'>
                  <div className='flex gap-[70px] justify-center items-center'>
                    {/* 절전 모드 */}
                    <div className='w-[220px] bg-secondary px-[72px] py-[50px] flex flex-col gap-10'>
                      <p className='text-center text-text20 text-primary'>절전 모드</p>
                    </div>
                    {/* 환풍기 출력 설정 */}
                    <div className='flex flex-col gap-12'>
                      <div>
                        <p className='text-text16 text-text-strong'>전력 소비 단계 설정</p>
                      </div>
                      <div className='flex flex-col gap-6'>
                        <button
                          className={`w-[338px] px-20 py-[13px] border border-border  ${
                            electricPowerResponse.usedStage === 1
                              ? 'cursor-default bg-primary text-white'
                              : 'cursor-pointer bg-white'
                          }`}
                          onClick={() => {
                            electricPowerResponse.usedStage !== 1 &&
                              electricPowerUsedStageChanged(1)
                          }}
                        >
                          <p className='text-text16'>1단계 (이용자 감지)</p>
                        </button>
                        <button
                          className={`w-[338px] px-20 py-[13px] border border-border  ${
                            electricPowerResponse.usedStage === 2
                              ? 'cursor-default bg-primary text-white'
                              : 'cursor-pointer bg-white'
                          }`}
                          onClick={() => {
                            electricPowerResponse.usedStage !== 2 &&
                              electricPowerUsedStageChanged(2)
                          }}
                        >
                          <p className='text-text16'>2단계 (절전 모드)</p>
                        </button>
                      </div>
                    </div>
                    {/* 실시간 부스 내 공기질 현황 */}
                    <div className='flex flex-col gap-20 bg-white '>
                      <p className='text-text16 text-text-strong'>전력 사용량</p>
                      <div className='flex justify-center mt-10'>
                        <img src={require('assets/images/usage2.png')} alt='' />
                      </div>
                      <p className='text-center text-text20 text-primary'>
                        {electricPowerResponse.kwh}
                        kWh
                      </p>
                    </div>
                  </div>
                  {/* 가동 중지 버튼 */}
                  <div className='flex items-center'>
                    <button
                      className={`${
                        electricPowerResponse.active
                          ? 'btn-primary_large'
                          : 'btn-secondary_small w-[400px] h-[52px] py-[15px] bg-text-sub text-gray cursor-auto'
                      } `}
                      onClick={() => {
                        electricPowerResponse.active && operationStopClicked('electric power')
                      }}
                    >
                      <p className='text-white text-text16'>가동중지</p>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isOperationControlModalOpen && (
        <ModalOperationControl
          selectedBooth={selectedBooth}
          selectedMode={selectedMode}
          // ventilator
          setVentilatorResponse={setVentilatorResponse}
          // airconditioner
          setAirconditionerResponse={setAirconditionerResponse}
          // electric power
          setElectricPowerResponse={setElectricPowerResponse}
          // modal open
          setIsOperationControlModalOpen={setIsOperationControlModalOpen}
        />
      )}
    </div>
  )
}
