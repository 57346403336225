import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Header from 'components/Header'
import SideMenuBar from 'components/SideMenuBar'
import LineChart from 'components/LineChart'

import DropDownSVG from 'assets/icons/chevron-down.svg'

import { getBoothList } from 'apis/boothlist.ts'
import { getBoothAirQuality } from 'apis/airquality.ts'

// const userData = [1, 1.5, 2.6, 3.7, 3, 1.2, 0, 0, 4.5, 3.5, 4.2]

// const labels = [
//   '09:30',
//   '09:35',
//   '09:40',
//   '09:45',
//   '09:50',
//   '09:55',
//   '10:00',
//   '10:05',
//   '10:10',
//   '10:15',
//   '10:20',
// ]

export default function BoothManagementAirquality() {
  const navigate = useNavigate()

  // selectedBooth: 현재 선택된 부스의 idx
  const [selectedBooth, setSelectedBooth] = useState(localStorage.getItem('selectedBoothIdx') || 0)
  // console.log(selectedBooth)

  // [BoothListApi]
  const [boothListResponse, setBoothListResponse] = useState([])
  const getBoothListApi = async () => {
    const getBoothListResponse = await getBoothList()

    if (getBoothListResponse.code !== '20') {
      alert(getBoothListResponse.message)
      navigate('/member/booth-registration')
      return
    }
    setBoothListResponse(getBoothListResponse.data)
    // console.log(getBoothListResponse.data)
    let firstBooth = getBoothListResponse.data[0].idx
    // console.log(firstBooth)

    setSelectedBooth(firstBooth)
    getBoothAirQualityApi(firstBooth)
  }

  // [BoothAirQualityApi]
  const [boothAirQualityResponse, setBoothAirQualityResponse] = useState({})
  const getBoothAirQualityApi = async (boothIdx) => {
    const getBoothAirQualityResponse = await getBoothAirQuality(boothIdx)

    if (getBoothAirQualityResponse.code !== '20') {
      alert(getBoothAirQualityResponse.message)
      return
    }
    setBoothAirQualityResponse(getBoothAirQualityResponse.data)
    // console.log(getBoothAirQualityResponse.data)
  }

  useEffect(() => {
    getBoothListApi()
    // eslint-disable-next-line
  }, [])

  const selectChanged = (e) => {
    setSelectedBooth(e.target.value)
    getBoothAirQualityApi(e.target.value)
    getBoothAirQualityApi(e.target.value)
    localStorage.setItem('selectedBoothIdx', e.target.value)
  }
  return (
    <div className='h-screen'>
      <Header />
      <div className='flex h-[calc(100%-60px)]'>
        <SideMenuBar />
        <div className='flex flex-col w-full h-full p-40 overflow-y-auto gap-30'>
          <div className='flex flex-col gap-30'>
            <p className='text-black text-text24'>내부 공기질 현황</p>
            <select
              value={selectedBooth}
              className='w-[250px] px-20 py-[15px] border border-gray bg-no-repeat bg-[center_right_20px]'
              style={{ backgroundImage: 'url(' + DropDownSVG + ')' }}
              onChange={(e) => selectChanged(e)}
            >
              {boothListResponse.map((boothItem) => (
                <option key={`${boothItem.idx}`} value={`${boothItem.idx}`}>
                  {boothItem.name}
                </option>
              ))}
            </select>

            {/* 일산화탄소(CO) */}
            <div className='flex flex-col gap-[22px]'>
              <p className='text-text16 text-text-strong'>일산화탄소(CO)</p>
              <div className='flex justify-end'>
                <div className='w-[1536px] h-[276px]'>
                  <LineChart
                    // key={reRender}
                    graphData={{
                      labels: boothAirQualityResponse.createdAt,
                      datasets: [
                        {
                          label: '일산화탄소(CO)',
                          data: boothAirQualityResponse.co,
                          // backgroundColor: 'rgba(255, 99, 99, 1)', // 포인트 배경색
                          borderColor: 'rgba(13, 36, 66, 1)',
                          // borderWidth: 3,
                          // pointRadius: 4, // 포인트 크기
                          // pointBorderWidth: 2,
                        },
                      ],
                    }}
                  />
                </div>
              </div>
            </div>

            {/* 이산화탄소(CO₂) */}
            <div className='flex flex-col gap-[22px]'>
              <p className='text-text16 text-text-strong'>이산화탄소(CO₂)</p>
              <div className='flex justify-end'>
                <div className='w-[1536px] h-[276px]'>
                  <LineChart
                    // key={reRender}
                    graphData={{
                      labels: boothAirQualityResponse.createdAt,
                      datasets: [
                        {
                          label: '이산화탄소(CO₂)',
                          data: boothAirQualityResponse.co2,
                          // backgroundColor: 'rgba(255, 99, 99, 1)', // 포인트 배경색
                          borderColor: 'rgba(13, 36, 66, 1)',
                          // borderWidth: 3,
                          // pointRadius: 4, // 포인트 크기
                          // pointBorderWidth: 2,
                        },
                      ],
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='flex flex-col gap-30'>
            <p className='text-black text-text24'>실내 공기질 타임라인</p>
            <div className='flex flex-col'>
              {/* 1행 */}
              <div className='flex justify-between items-center h-[40px] bg-gray text-text16 text-text-strong text-center divide-x divide-border'>
                <p className='w-[415px]'>구분</p>
                <p className='w-[415px] '>시간</p>
                <p className='w-[415px] '>현황</p>
                <p className='w-[415px] '>권고수치</p>
                {/* <p className='w-[332px] '>위치</p> */}
              </div>
              {/* 2행 */}
              <div className='flex justify-between items-center h-[60px] border-b border-border text-text16 text-center divide-x divide-border'>
                <p className='w-[415px]'>미세먼지</p>
                <p className='w-[415px]'>14:00</p>
                <p className='w-[415px]'>
                  {boothAirQualityResponse.dust?.[boothAirQualityResponse.dust?.length - 1] ||
                    'N/A'}
                  ㎍/m³
                </p>
                <p className='w-[415px]'>50㎍/m³</p>
                {/* <p className='w-[332px]'>00동 00플라자 부스</p> */}
              </div>
              {/* 3행 */}
              <div className='flex justify-between items-center h-[60px] border-b border-border text-text16 text-center divide-x divide-border'>
                <p className='w-[415px]'>일산화탄소(CO)</p>
                <p className='w-[415px]'>14:00</p>
                <p className='w-[415px]'>
                  {boothAirQualityResponse.co?.[boothAirQualityResponse.co?.length - 1] || 'N/A'}ppm
                </p>
                <p className='w-[415px]'>300ppm</p>
                {/* <p className='w-[332px]'>00동 00플라자 부스</p> */}
              </div>
              {/* 4행 */}
              <div className='flex justify-between items-center h-[60px] border-b border-border text-text16 text-center divide-x divide-border'>
                <p className='w-[415px]'>이산화탄소(CO₂)</p>
                <p className='w-[415px]'>14:00</p>
                <p className='w-[415px]'>
                  {boothAirQualityResponse.co2?.[boothAirQualityResponse.co2?.length - 1] || 'N/A'}
                  ppm
                </p>
                <p className='w-[415px]'>1,500ppm</p>
                {/* <p className='w-[332px]'>00동 00플라자 부스</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
