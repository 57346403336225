import { useRef } from 'react'

import { getBoothVentilator } from 'apis/ventilator.ts'
import { PostBoothVentilatorActive } from 'apis/ventilatorActive.ts'
import { GetBoothAriconditioner } from 'apis/airconditioner.ts'
import { postBoothAirconditionerActive } from 'apis/airconditionerActive.ts'
import { GetBoothElectricPower } from 'apis/electricpower.ts'
import { postBoothElectricPowerActive } from 'apis/electricpowerActive.ts'

export default function ModalOperationControl({
  selectedBooth,
  selectedMode,
  setVentilatorResponse,
  setAirconditionerResponse,
  setElectricPowerResponse,
  setIsOperationControlModalOpen,
}) {
  const modalBackground = useRef()

  // [VentilatorApi]
  const getBoothVentilatorApi = async (boothIdx) => {
    const getBoothVentilatorResponse = await getBoothVentilator(boothIdx)

    if (getBoothVentilatorResponse.code !== '20') {
      alert(getBoothVentilatorResponse.message)
      return
    }
    setVentilatorResponse(getBoothVentilatorResponse.data)
    // console.log(getBoothVentilatorResponse.data)
  }

  // [AriConditionerApi]
  const getBoothAirConditionerApi = async (boothIdx) => {
    const getBoothAirConditionerResponse = await GetBoothAriconditioner(boothIdx)

    if (getBoothAirConditionerResponse.code !== '20') {
      alert(getBoothAirConditionerResponse.message)
      return
    }
    setAirconditionerResponse(getBoothAirConditionerResponse.data)
    // console.log(getBoothAirConditionerResponse.data)
  }

  // [ElectricPowerApi]
  const getBoothElectricPowerApi = async (boothIdx) => {
    const getBoothElectricPowerResponse = await GetBoothElectricPower(boothIdx)

    if (getBoothElectricPowerResponse.code !== '20') {
      alert(getBoothElectricPowerResponse.message)
      return
    }
    setElectricPowerResponse(getBoothElectricPowerResponse.data)
    // console.log(getBoothElectricPowerResponse.data)
  }

  // [Ventilator 활성화 api]
  const postVentilatorActiveApi = async () => {
    const postVentilatorActiveResponse = await PostBoothVentilatorActive(selectedBooth, {
      active: false,
    })

    if (postVentilatorActiveResponse.code !== '20') {
      alert(postVentilatorActiveResponse.message)
      return
    }

    // console.log(postVentilatorActiveResponse)
  }

  // [Airconditioner 활성화 api]
  const postBoothAirconditionerActiveApi = async () => {
    const postBoothAirconditionerActiveResponse = await postBoothAirconditionerActive(
      selectedBooth,
      {
        active: false,
      }
    )

    if (postBoothAirconditionerActiveResponse.code !== '20') {
      alert(postBoothAirconditionerActiveResponse.message)
      return
    }

    // console.log(postBoothAirconditionerActiveResponse)
  }

  // [ElectricPower 활성화 api]
  const postBoothElectricPowerActiveApi = async () => {
    const postBoothElectricPowerActiveResponse = await postBoothElectricPowerActive(selectedBooth, {
      active: false,
    })

    if (postBoothElectricPowerActiveResponse.code !== '20') {
      alert(postBoothElectricPowerActiveResponse.message)
      return
    }
    // console.log(postBoothElectricPowerActiveResponse)
  }

  const clickYes = async () => {
    if (selectedMode === 'ventilator') {
      await postVentilatorActiveApi()
      await getBoothVentilatorApi(selectedBooth)
    }
    if (selectedMode === 'airconditioner') {
      await postBoothAirconditionerActiveApi()
      await getBoothAirConditionerApi(selectedBooth)
    }
    if (selectedMode === 'electric power') {
      await postBoothElectricPowerActiveApi()
      await getBoothElectricPowerApi(selectedBooth)
    }

    setIsOperationControlModalOpen((prev) => !prev)
  }

  return (
    <div className='fixed top-0 left-0 z-50 w-screen h-screen cursor-default'>
      {/* Background of Modal */}
      <div
        className='absolute w-full h-full bg-black/40'
        ref={modalBackground}
        onClick={(e) => {
          if (e.target === modalBackground.current) setIsOperationControlModalOpen((prev) => !prev)
        }}
      />
      {/* Modal */}
      <div className='absolute w-[335] h-[200px] transform -translate-x-1/2 -translate-y-1/2 bg-white top-1/2 left-1/2 '>
        <div className='pt-[50px] flex flex-col justify-between h-full'>
          <div className='px-[50px] flex flex-col gap-12 items-center'>
            <p className='text-text20'>가동중지 상태로 전환하시겠습니까?</p>
          </div>

          <div className='flex'>
            <div className='w-full btn-secondary_small py-[15px] ' onClick={clickYes}>
              <p>예</p>
            </div>
            <div
              className='w-full btn-primary_small py-[15px]'
              onClick={() => setIsOperationControlModalOpen((prev) => !prev)}
            >
              <p>아니요</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
