import { useEffect, useState } from 'react'

import LogoHeader from 'assets/logos/logo-header.svg'

import { useSetRecoilState } from 'recoil'
import loginState from 'states/loginState.ts'

// api
import { getUserProfile } from 'apis/profile.ts'
import { postUserLogout } from 'apis/logout.ts'

export default function Header() {
  const [userName, setUserName] = useState('')
  const setLogin = useSetRecoilState(loginState)

  // UserProfile api
  const getUserProfileApi = async () => {
    const getUserProfileResponse = await getUserProfile()

    if (getUserProfileResponse.code !== '20') {
      alert(getUserProfileResponse.message)
      return
    }
    setUserName(getUserProfileResponse.data.name)
  }

  const postUserLogoutApi = async () => {
    const postUserLogoutResponse = await postUserLogout()

    if (postUserLogoutResponse.code !== '20') {
      alert(postUserLogoutResponse.message)
      return
    }
    setLogin({ isLoggedIn: false })
    // navigate('/login', { replace: true })
  }

  useEffect(() => {
    getUserProfileApi()
  }, [])

  return (
    <div className='w-full h-[60px] px-30 py-[17px] bg-primary border-b-2 border-gray'>
      <div className='flex justify-between'>
        <div className='flex items-center gap-6'>
          <div>
            <img src={LogoHeader} alt='' />
          </div>
          <p className='text-white text-text20'>굿스페이스 스마트 부스</p>
          <p className='text-text14 text-gray'>관리자</p>
        </div>
        <div className='flex gap-32'>
          <p className='text-white cursor-pointer select-none text-text16'>{userName}님</p>
          <button onClick={postUserLogoutApi}>
            <p className='cursor-pointer select-none text-text16 text-red'>로그아웃</p>
          </button>
        </div>
      </div>
    </div>
  )
}
