import { CommonResponse, post } from './common.ts'

// POST v1/booth/{idx}/electric-power/active

interface PostBoothElectricPowerActiveRequest {
  active: boolean
}

export const postBoothElectricPowerActive = async (
  idx: number,
  body: PostBoothElectricPowerActiveRequest
): Promise<CommonResponse<null>> => {
  return await post(`/booth/${idx}/electric-power/active`, body)
}
