import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Legend, scales } from 'chart.js'

import { Bar } from 'react-chartjs-2'

ChartJS.register(BarElement, CategoryScale, LinearScale, Legend, scales)

const options = {
  responsive: true,
  indexAxis: 'y',
  maintainAspectRatio: false, // 가로 세로 비율 유지 안함
  scales: {
    x: {
      display: true,
      position: 'bottom',
      min: 0,
      suggestedMax: 1000,
    },
  },

  plugins: {
    legend: {
      display: false,
      position: 'bottom',
      labels: {
        font: {
          size: 14,
        },
      },
    },
    title: {
      display: true,
      text: 'electricboard',
    },
  },
}

export default function BarChartCo2({ graphData }) {
  // 컴포넌트 prop: data, type, options
  return <Bar options={options} data={graphData} />
}
