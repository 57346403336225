import { useState, useRef, useEffect } from 'react'

import Close from 'assets/icons/close.svg'
import { postAdminUpdate } from 'apis/adminupdate.ts'

const permissionHangul = {
  admin_manage: '관리자 관리',
  booth_manage: '부스 관리',
  booth_control: '부스 제어',
  remote_support: '원격 지원',
  setting_manage: '설정 관리',
}
export default function ModalModifyAuthority({
  refresh,
  selectedModifyIdx,
  memberList,
  setIsModifyAuthorityModalOpen,
}) {
  const modalBackground = useRef()

  // 체크박스 아이템 상태관리
  const [permission, setPermission] = useState({
    admin_manage: false,
    booth_manage: false,
    booth_control: false,
    remote_support: false,
    setting_manage: false,
  })

  // 개별 체크박스 상태 업데이트 함수
  const handleCheck = (key) => {
    let newPermission = {
      ...permission,
    }
    newPermission[key] = !permission[key]
    setPermission(newPermission)
  }

  const loadCheck = async () => {
    for await (var member of memberList) {
      if (member.idx === selectedModifyIdx) {
        let newPermission = JSON.parse(member.permission)
        setPermission(newPermission)
      }
    }
  }

  useEffect(() => {
    loadCheck()
    // eslint-disable-next-line
  }, [])

  const postAdminUpdateApi = async () => {
    const permissionJson = JSON.stringify(permission)

    const postAdminUpdateResponse = await postAdminUpdate({
      idx: selectedModifyIdx,
      permission: permissionJson,
    })

    if (postAdminUpdateResponse.code !== '20') {
      alert(postAdminUpdateResponse.message)
      return
    }

    // console.log(postAdminUpdateResponse.message)
    refresh()
  }

  const onSubmit = async () => {
    await postAdminUpdateApi()
    setIsModifyAuthorityModalOpen((prev) => !prev)
    refresh()
  }

  return (
    <div className='fixed top-0 left-0 z-50 w-screen h-screen cursor-default'>
      {/* Background of Modal */}
      <div
        className='absolute w-full h-full bg-black/40'
        ref={modalBackground}
        onClick={(e) => {
          if (e.target === modalBackground.current) setIsModifyAuthorityModalOpen((prev) => !prev)
        }}
      />
      {/* Modal */}
      <div className='absolute w-[860px] transform -translate-x-1/2 -translate-y-1/2 bg-white top-1/2 left-1/2 '>
        <div className='pt-[50px] flex flex-col gap-40'>
          <div className='px-[50px] flex justify-between'>
            <p className='text-text24'>권한 수정</p>
            <button
              className='flex items-center'
              onClick={() => setIsModifyAuthorityModalOpen((prev) => !prev)}
            >
              <img src={Close} alt='' />
            </button>
          </div>
          <div className='px-[50px]'>
            <div className='px-[53px] py-[53px] border border-border'>
              <div className='grid grid-cols-3 gap-30'>
                {Object.keys(permissionHangul).map((item, index) => (
                  <div key={index} className='flex gap-20'>
                    <div className='flex items-center justify-center px-12'>
                      <label
                        htmlFor=''
                        className='w-20 h-20 bg-white bg-center bg-no-repeat bg-cover border shrink-0 border-border'
                      >
                        <input
                          id={`${index}`}
                          type='checkbox'
                          checked={permission[item]} // 체크된 상태 확인
                          onChange={() => handleCheck(item)} // 개별 체크박스 선택/해제
                          className='w-20 h-20 bg-center bg-no-repeat appearance-none'
                        />
                      </label>
                    </div>
                    <label htmlFor={`${index}`}>{permissionHangul[item]}</label>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <button className='w-full btn-primary_large px-[185px] py-[15px] ' onClick={onSubmit}>
            <p>완료</p>
          </button>
        </div>
      </div>
    </div>
  )
}
