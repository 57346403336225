import { useEffect, useState } from 'react'
import Header from 'components/Header'
import SideMenuBar from 'components/SideMenuBar'

import LeftArrowSVG from 'assets/icons/chevron-left.svg'
import RightArrowSVG from 'assets/icons/chevron-right.svg'
import ModalModifyAuthority from 'components/ModalModifyAuthority'
import ModalAddAdministrator from 'components/ModalAddAdministrator'

// api
import { getAdminList } from 'apis/adminlist.ts'
import { postAdminDelete } from 'apis/admindelete.ts'

const indexList = [
  'NO.',
  '권한',
  '이름',
  '아이디',
  '전화번호',
  '이메일',
  '최근 수정일',
  '최근 수정자',
  '등록일',
  '권한수정',
]

// const memberList = [
//   {
//     no: 1,
//     authority: '최고 관리자',
//     name: '김길동',
//     id: 'kildong',
//     phoneNum: '010-1234-1234',
//     email: '123@space.io',
//     modifiedDate: '2024-06-20',
//     modifier: '123@space.io',
//     registerDate: '2024-06-20',
//   },
//   {
//     no: 2,
//     authority: '부 관리자',
//     name: '김길동',
//     id: 'kildong',
//     phoneNum: '010-1234-1234',
//     email: '123@space.io',
//     modifiedDate: '2024-06-20',
//     modifier: '123@space.io',
//     registerDate: '2024-06-20',
//   },
//   {
//     no: 3,
//     authority: '운영 관리자',
//     name: '김길동',
//     id: 'kildong',
//     phoneNum: '010-1234-1234',
//     email: '123@space.io',
//     modifiedDate: '2024-06-20',
//     modifier: '123@space.io',
//     registerDate: '2024-06-20',
//   },
// ]

export default function Member() {
  // [ getAdminListApi ]
  const [memberList, setMemberList] = useState([])
  const [currentPageElementNumber, setCurrentPageElementNumber] = useState(1)
  const getAdminListApi = async (pageNum) => {
    const getAdminListResponse = await getAdminList(pageNum)

    if (getAdminListResponse.code !== '20') {
      alert(getAdminListResponse.message)
      return
    }
    setMemberList([...getAdminListResponse.data.admins])
    setMaxContentPage(getAdminListResponse.data.maxPage)
    setCurrentPageElementNumber(getAdminListResponse.data.currentPageElementNumber)
    // console.log(getAdminListResponse)
  }

  useEffect(() => {
    getAdminListApi(1)
  }, [])

  // 수정하기 상태
  const [editStatus, setEditStatus] = useState(false)
  // 권한수정 활성화 상태
  const [modifyAuthorityStatus, setModifyAuthorityStatus] = useState(true)

  // 권한수정 대상(IDX)
  const [selectedModifyIdx, setSelectedModifyIdx] = useState(0)
  // 모달-권한수정 활성화 상태
  const [isModifyAuthorityModalOpen, setIsModifyAuthorityModalOpen] = useState(false)

  // 모달-관리자추가 활성화 상태
  const [isAddAdministratorModalOpen, setIsAddAdministratorModalOpen] = useState(false)

  // 수정하기 상태 & 권한수정 활성화 상태 토글 함수
  function toggleEditStatus() {
    setEditStatus((prev) => !prev)
    setModifyAuthorityStatus((prev) => !prev)
  }

  // 권한수정 버튼 클릭 -> 선택된수정대상ID 저장 & 모달 오픈하기
  function modifyAuthorityModal(Idx) {
    if (modifyAuthorityStatus) {
      setSelectedModifyIdx(Idx)
      setIsModifyAuthorityModalOpen(true)
    }
  }

  // 페이지 넘버
  const [currentPage, setCurrentPage] = useState(1)
  const [maxContentPage, setMaxContentPage] = useState(0)

  const prevPage = () => {
    setCurrentPage(currentPage === 1 ? 1 : currentPage - 1)
    getAdminListApi(currentPage === 1 ? 1 : currentPage - 1)
  }

  const nextPage = () => {
    setCurrentPage(currentPage === maxContentPage ? maxContentPage : currentPage + 1)
    getAdminListApi(currentPage === maxContentPage ? maxContentPage : currentPage + 1)
  }

  const movePage = (page) => {
    setCurrentPage(page)
    getAdminListApi(page)
  }

  ////////// 체크 박스 //////////

  // 체크박스 아이템 상태관리
  const [checkedItems, setCheckedItems] = useState([])

  // 개별 체크박스 상태 업데이트 함수
  const handleCheck = (id) => {
    setCheckedItems((prev) => {
      // 체크 해제 시 상태에서 제거
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id)
      }
      // 체크 시 상태에 추가
      else {
        return [...prev, id]
      }
    })
    // console.log(checkedItems)
  }

  // 체크박스 전체선택/해제
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false)

  const selectAll = () => {
    const selectAllCheckbox = document.querySelector('.selectAllCheckBox')
    const checkboxes = document.querySelectorAll('.memberCheckBox')

    if (selectAllCheckbox.checked === true) {
      let selectedAll = []

      checkboxes.forEach((checkbox) => {
        selectedAll.push(+checkbox.id)
      })
      setCheckedItems([...selectedAll])
    } else {
      setCheckedItems([])
    }
  }

  useEffect(() => {
    function selectAllCheckboxStatus() {
      if (checkedItems.length === currentPageElementNumber) {
        setIsSelectAllChecked(true)
      } else {
        setIsSelectAllChecked(false)
      }
    }
    selectAllCheckboxStatus()
    // console.log(checkedItems)
  }, [checkedItems, currentPageElementNumber])

  // [ AdminDeleteApi ]
  const postAdminDeleteApi = async () => {
    for await (var checkedItem of checkedItems) {
      const postAdminDeleteResponse = await postAdminDelete({ idx: checkedItem })
      if (postAdminDeleteResponse.code !== '20') {
        alert(postAdminDeleteResponse.message)
        return
      }
    }

    setCheckedItems([])
    await getAdminListApi(currentPage)
  }

  const refresh = async () => {
    await getAdminListApi(currentPage)
  }

  const clickDeleteButton = async () => {
    await postAdminDeleteApi()
  }

  return (
    <div className='h-screen'>
      <Header />
      <div className='flex h-[calc(100%-60px)]'>
        <SideMenuBar />
        <div className='flex flex-col w-full h-full gap-40 p-40 overflow-y-auto'>
          <div className='flex flex-col w-full gap-10'>
            {/* (1) 권한 관리 */}
            <div className='flex items-center gap-20'>
              <p className='text-text24 text-text'>권한 관리</p>
              <div>
                {!editStatus && (
                  <button className='px-16 py-8 btn-secondary_small' onClick={toggleEditStatus}>
                    <p className='text-text14 text-text'>수정하기</p>
                  </button>
                )}
                {/* editStatus(수정하기) 활성화 시 추가/삭제하기 버튼 보이도록 */}
                {editStatus && (
                  <div className='flex gap-12'>
                    <button
                      className='px-16 py-8 btn-secondary_small '
                      onClick={() => setIsAddAdministratorModalOpen(true)}
                    >
                      <p className='text-text14 text-text'>추가하기</p>
                    </button>
                    <button className='px-16 py-8 btn-secondary_small ' onClick={clickDeleteButton}>
                      <p className='text-text14 text-red'>삭제하기</p>
                    </button>
                  </div>
                )}
              </div>
            </div>

            {/* (2) 표 */}
            <div className='flex flex-col gap-16'>
              {/* 목록 */}
              <div className='flex justify-end'>
                <p className='text-text16 text-text-strong'>목록({memberList.length})</p>
              </div>

              <div className='flex flex-col gap-12'>
                {/* 리스트 - 인덱스 */}
                <div className='bg-gray h-[40px] flex items-center justify-between text-text16 text-text-strong text-center'>
                  {editStatus && (
                    // 체크박스 전체선택/해제 버튼
                    <div className='flex items-center justify-center px-12'>
                      <label
                        htmlFor='selectAll'
                        className='w-20 h-20 bg-white bg-center bg-no-repeat bg-cover border shrink-0 border-border'
                      >
                        <input
                          id='selectAll'
                          type='checkbox'
                          checked={isSelectAllChecked}
                          onChange={() => setIsSelectAllChecked((prev) => !prev)}
                          className='w-20 h-20 bg-center bg-no-repeat appearance-none selectAllCheckBox'
                          onClick={() => selectAll()}
                        />
                      </label>
                    </div>
                  )}
                  {indexList.map((indexItem, index) => (
                    <div
                      key={index}
                      className={`flex items-center justify-center pb-2
                ${index === 0 ? 'w-[130px] shrink-0' : 'w-full'}
                ${index === 10 ? '' : 'border-r border-r-border'}`}
                    >
                      <p>{indexItem}</p>
                    </div>
                  ))}
                </div>

                {/* 리스트 - 데이터 */}
                <div className='flex flex-col'>
                  {memberList.map((memberItem) => (
                    <div
                      key={memberItem.idx}
                      className='h-[60px] flex items-center justify-between text-text16 text-text text-center border-b border-b-border'
                    >
                      {editStatus && (
                        <div className='flex items-center justify-center px-12'>
                          <label
                            htmlFor=''
                            className='w-20 h-20 bg-white bg-center bg-no-repeat bg-cover border shrink-0 border-border'
                          >
                            <input
                              id={memberItem.idx}
                              type='checkbox'
                              checked={checkedItems.includes(memberItem.idx)} // 체크된 상태 확인
                              onChange={() => handleCheck(memberItem.idx)} // 개별 체크박스 선택/해제
                              className='w-20 h-20 bg-center bg-no-repeat appearance-none memberCheckBox'
                            />
                          </label>
                        </div>
                      )}
                      <div className='flex items-center justify-center pb-2 w-[130px] shrink-0 border-r border-r-border'>
                        <p>{memberItem.idx}</p>
                      </div>
                      <div className='flex items-center justify-center w-full pb-2 border-r border-r-border'>
                        <p>{memberItem.authority}</p>
                      </div>
                      <div className='flex items-center justify-center w-full pb-2 border-r border-r-border'>
                        <p>{memberItem.name}</p>
                      </div>
                      <div className='flex items-center justify-center w-full pb-2 border-r border-r-border'>
                        <p>{memberItem.id}</p>
                      </div>
                      <div className='flex items-center justify-center w-full pb-2 border-r border-r-border'>
                        <p>{memberItem.phoneNumber}</p>
                      </div>
                      <div className='flex items-center justify-center w-full pb-2 border-r border-r-border'>
                        <p>{memberItem.email}</p>
                      </div>
                      <div className='flex items-center justify-center w-full pb-2 border-r border-r-border'>
                        <p>{memberItem.updatedAt}</p>
                      </div>
                      <div className='flex items-center justify-center w-full pb-2 border-r border-r-border'>
                        <p>{memberItem.updatedBy}</p>
                      </div>
                      <div className='flex items-center justify-center w-full pb-2 border-r border-r-border'>
                        <p>{memberItem.createdAt}</p>
                      </div>
                      <div className='flex items-center justify-center w-full pb-2 '>
                        <button
                          className={`px-16 py-8 btn-primary_small ${
                            editStatus ? 'bg-text-sub text-gray cursor-auto' : 'cursor-pointer'
                          }`}
                          onClick={() => modifyAuthorityModal(memberItem.idx)}
                        >
                          <p>권한수정</p>
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* (4) - 페이지 버튼 */}
          {!editStatus && (
            <div className='flex justify-center w-full gap-8'>
              <div className='flex gap-2 px-8 py-6 btn-secondary_small' onClick={prevPage}>
                <img src={LeftArrowSVG} alt='' />
                <p>이전</p>
              </div>
              {Array.from({ length: maxContentPage }, (_, i) => i + 1).map((page) => (
                <div
                  key={page}
                  className={`${
                    currentPage === page ? 'btn-primary_small' : 'btn-secondary_small'
                  } w-40 h-40 py-6 cursor-pointer`}
                  onClick={() => movePage(page)}
                >
                  <p>{page}</p>
                </div>
              ))}
              <div className='flex gap-2 px-8 py-6 btn-secondary_small' onClick={nextPage}>
                <p>다음</p>
                <img src={RightArrowSVG} alt='' />
              </div>
            </div>
          )}
          {editStatus && (
            <div className='flex justify-center w-full'>
              <button className='btn-primary_large px-[185px] py-[15px]' onClick={toggleEditStatus}>
                <p>완료</p>
              </button>
            </div>
          )}
        </div>
      </div>

      {/* 관리자추가 모달 */}
      {isAddAdministratorModalOpen && (
        <ModalAddAdministrator
          refresh={refresh}
          setIsAddAdministratorModalOpen={setIsAddAdministratorModalOpen}
        />
      )}

      {/* 권한수정 모달 */}
      {isModifyAuthorityModalOpen && (
        <ModalModifyAuthority
          refresh={refresh}
          selectedModifyIdx={selectedModifyIdx}
          memberList={memberList}
          setIsModifyAuthorityModalOpen={setIsModifyAuthorityModalOpen}
        />
      )}
    </div>
  )
}
