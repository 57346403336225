import { useLocation, useNavigate } from 'react-router-dom'

import ChevronDownSVG from 'assets/icons/chevron-down.svg'

export default function SideMenuBar() {
  const location = useLocation()
  const navigate = useNavigate()

  return (
    <nav className='w-[180px] flex flex-col border-r-2 border-r-gray'>
      {/* 관리자 계정관리 */}
      <div className='w-[180px] px-20 py-[14px] bg-primary flex justify-center'>
        <p className='text-center text-white text-text16'>관리자 계정관리</p>
      </div>

      {/* 회원 */}
      <div className='sidebar-tab'>
        <p>회원</p>
        <div>
          <img src={ChevronDownSVG} alt='' />
        </div>
      </div>
      {/* 회원-권한관리 */}
      <div
        className={`${
          location.pathname === '/member' ? 'sidebar-subtab_selected' : 'sidebar-subtab'
        } `}
        onClick={() => navigate('/member')}
      >
        <p>권한관리</p>
      </div>
      {/* 회원-부스관리 */}
      <div
        className={`${
          location.pathname === '/member/booth-registration'
            ? 'sidebar-subtab_selected'
            : 'sidebar-subtab'
        } `}
        onClick={() => navigate('/member/booth-registration')}
      >
        <p>부스 등록</p>
      </div>

      {/* 부스 관리 */}
      <div className='sidebar-tab'>
        <p>부스 관리</p>
        <div>
          <img src={ChevronDownSVG} alt='' />
        </div>
      </div>
      {/* 부스 관리 - 부스 관리 */}
      <div
        className={`${
          location.pathname === '/boothmanagement' ? 'sidebar-subtab_selected' : 'sidebar-subtab'
        } `}
        onClick={() => navigate('/boothmanagement')}
      >
        <p>부스 관리</p>
      </div>
      {/* 부스 관리 - 부스 제어 */}
      <div
        className={`${
          location.pathname === '/boothmanagement/control'
            ? 'sidebar-subtab_selected'
            : 'sidebar-subtab'
        } `}
        onClick={() => navigate('/boothmanagement/control')}
      >
        <p>부스 제어</p>
      </div>
      {/* 부스 관리 - 부스 운영현황 */}
      <div
        className={`${
          location.pathname === '/boothmanagement/operation-status'
            ? 'sidebar-subtab_selected'
            : 'sidebar-subtab'
        } `}
        onClick={() => navigate('/boothmanagement/operation-status')}
      >
        <p>부스 운영현황</p>
      </div>
      {/* 부스 관리 - 내부 공기질 현황 */}
      <div
        className={`${
          location.pathname === '/boothmanagement/airquality'
            ? 'sidebar-subtab_selected'
            : 'sidebar-subtab'
        } `}
        onClick={() => navigate('/boothmanagement/airquality')}
      >
        <p>내부 공기질 현황</p>
      </div>

      {/* 설정 */}
      {/* <div className='sidebar-tab'>
        <p>설정</p>
        <div>
          <img src={ChevronDownSVG} alt='' />
        </div>
      </div>
      <div className='sidebar-subtab'>
        <p>Admin 설정</p>
      </div> */}
    </nav>
  )
}
