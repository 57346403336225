import { CommonResponse, post } from './common.ts'

// POST v1/booth/{idx}/electric-power/used-stage

interface PostBoothElectricPowerUsedStageRequest {
  stage: number
}

export const postBoothElectricPowerUsedStage = async (
  idx: number,
  body: PostBoothElectricPowerUsedStageRequest
): Promise<CommonResponse<null>> => {
  return await post(`/booth/${idx}/electric-power/used-stage`, body)
}
